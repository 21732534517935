import React, { useEffect, useState } from 'react';
import Calendar from 'moedim';
import styled from 'styled-components';
import { FiX, FiArrowUpRight, FiArrowDownRight, FiPlus, FiTruck, FiChevronDown, FiCalendar, FiCircle } from "react-icons/fi";

import { ReactSortable } from 'react-sortablejs';
import { selectGuardianState } from '../../redux/auth/GuardianSlice';

// components
import Modal from 'react-bootstrap/Modal';
import LaneCard from '../../views/RoutePlanning/CalculatedRoute/LaneCard';
// hooks
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useParameter } from '../../hooks/useParameter';
//services
import requestCalculate from '../../services/user_routes/requestCalculate';
import Swal from 'sweetalert2';

const OverlayDetails = (props) => {
    const { show, setShow, calendar, data = [], setRoute = false } = props;

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    
    const history = useHistory();

    const user_token = useSelector(selectGuardianState);

    const handleClose = () => setShow(false);

    const load_sizes = useParameter("load_sizes");

    const [dropdown, setDropdown] = useState(false);
    const [rangeLength, setRangeLength] = useState(50);
    const [rangeWeight, setRangeWeight] = useState(50);
    const [dateValue, setDateValue] = useState(new Date());
    const [dateDropdown, setDateDropdown] = useState(false);
    const [y, setY] = useState(window.scrollY);
    const trailer_types = useParameter("trailer_types");
    const [arrList, setArrList] = useState([]);
    
    useEffect(() => {
        let arr = [];
        data.forEach((item, index)=> {
            let lane = {
                lane_load_size_name: item.lane_load_size_name,
                lane_load_size: item.lane_load_size,  
            };
            arr.push({
                ...lane,
                lane_place: `${item.lane_departure_city}, ${item.lane_departure_state}`,
                lane_place_id: item.lane_departure_place_id,
                // lane_type
            });
            if(data.length - 1 == index) {
                arr.push({
                    ...lane,
                    lane_place: `${item.lane_destination_city}, ${item.lane_destination_state}`,
                    lane_place_id: item.lane_destination_place_id,
                });
            }
        });
        console.log('arr',arr);
        setArrList(arr);
    }, [data]);
   
    const addItem = () => {
        setArrList([...arrList, {
            id: arrList.length + 1,
            value_input_place: "Los Angeles",
            vehicle_state: 'LFT',
            lane_state: 'DROP OFF'
        }])
    }

    const updateItem = (item, index) => {
        let data = arrList;
		data[index] = item;
        validateChange(data, index);
        setArrList(data);
	};

    const removeItem = (index) => {
        const temp = [...arrList];
        temp.splice(index, 1);
        validateChange(temp, index);
        setArrList(temp);
    }

    const validateChange = (data, index) => {
        // console.log(index);
        // console.log('index - 1', data[index - 1]);
        if((data[index - 1]?.lane_load_size_name ?? '') == 'FTL' && data[index].lane_type == 'PICK UP'){
            Swal.fire({ 
                text: `The lane ${index + 1} cannot be a PICK UP`,
                background: '#121A1F',
                customClass: {
                    container: 'alertClassCustom',
                },
            });
        }
        // console.log('index', data[index]);
    }

    const StyledCalendar = styled(Calendar)`
        --moedim-primary: #1c8c4f;
        width: 260px;
        border: 1px solid #364752;
        margin: 1rem 0;
        color: #cacaca;
        border-radius: 16px;
        background: #121A1F;
        padding: 24px 24px 24px 15px;
    `;

    const formatDate = () => {
        var date = dateValue.toLocaleDateString("en-US");
        return date;
    }

    useEffect(() => {
        setArrList(data);
        window.addEventListener("scroll", () => setDateDropdown(false));
        return () => {
            window.removeEventListener("scroll", () => setDateDropdown(false));
        };
    }, [y])

    const submitCalculate = async () => {
        // data = { ...data, points: arrList, date_value: dateValue, range_length: rangeLength, range_weight:  rangeWeight  }
        let data = {points: arrList};
        let response = await requestCalculate(data, user_token);
        console.log(response);
        if(response.state != 200){
            return  Swal.fire({ 
                        text: response.message,
                        background: '#121A1F',
                        customClass: {
                            container: 'alertClassCustom',
                        },
                    });
        }
        setRoute && setRoute(response.data);
        setShow(false);
        Swal.fire({
            background: '#121A1F',
            title: 'Wait a minute',
            text: 'Loading data',
            showConfirmButton: false,
            timer: 2000,
            width: '305px',
        })
        return;
        if(response.state === 200){
            return history.replace('/route-planning/results/user/'+response?.data?.user_route_id)
        }
        console.log(response)
    }

    return (
        <>
            <Modal show={show} onHide={handleClose}
                centered
                dialogClassName="modal-80w"
                className='modalDetails'
            >
                <Modal.Header>
                    <Modal.Title>Customize route</Modal.Title>
                    <FiX className='btn-closeModal' onClick={handleClose} />
                </Modal.Header>
                {/* <form onSubmit={handleSubmit(submitCalculate)}> */}
                    <div className='contentBody'>
                        <div className='title mb-3'>
                            <span>Route</span>
                        </div>

                        <ReactSortable
                            group="groupName"
                            animation={200}
                            handle={'.dragging-handle'}
                            delayOnTouchStart={true}
                            delay={2}
                            list={arrList} setList={setArrList}
                            onChange={(e) => validateChange(arrList, e.newIndex)}
                        >
                            {arrList.map((item, index) => <LaneCard 
                                data={item} index={index}
                                onRemoveItem={removeItem}
                                loadSizes={ arrList.length - 1 !== index ? load_sizes : false }
                                position={ index === 0 ? 'first' : arrList.length - 1 === index ? 'last' : ''}
                                onUpdateItem={updateItem}
                             /> )}
                        </ReactSortable>

                        <div className='mb-3 w-100'>
                            <button type="button" className='btn-modalSave w-100' onClick={() => addItem()}>
                                <FiPlus />
                                ADD LANE
                            </button>
                        </div>
                        {calendar &&
                            <div>
                                <div className='title mb-3'>
                                    <span>Date</span>
                                </div>
                                <div className="col-md-12 d-flex flex-row mb-3 w-100">
                                    <button  onClick={() => setDateDropdown(!dateDropdown)} type="button" className="dateDrop w-100">
                                        <FiCalendar className='letterDeparture' />
                                        <span className='letterDeparture'>{formatDate()}</span>
                                    </button>
                                </div>
                                {dateDropdown &&
                                    <div className='col-md-12 d-flex flex-row gap-2'>
                                        <div className="col-md-12 mt-2">
                                            <StyledCalendar className='calendar' value={dateValue} onChange={(d) => setDateValue(d)} />
                                        </div>
                                    </div>}
                                <div className="col-md-12 d-flex flex-column mb-3 w-100">
                                    <span className='letterSettings mb-3'>Max days en route</span>
                                    <select className="selectDetails w-100">
                                        <option>1 Day</option>
                                        <option>2 Days</option>
                                        <option>3 Days</option>
                                        <option>4 Days</option>
                                    </select>
                                </div>

                                <div className='title mb-3'>
                                    <span>Details</span>
                                </div>
                                <div className="col-md-12 d-flex flex-column mb-3 w-100">
                                    <span className='letterSettings mb-3'>Trailer type</span>
                                    <select className="selectDetails w-100" {...register(`trailer_type`, {
                                                    required: true
                                                })}>
                                        {trailer_types.map(e => <option value={e.pv_id} key={e.pv_id}>{e.pv_name}</option>)}
                                    </select>
                                </div>
                                <div className='col-md-12 mt-3 mb-3'>
                                    <div onClick={() => setDropdown(!dropdown)} className="btnDetails w-100">
                                        <span className='letterDetails'>Advance details</span>
                                        <FiChevronDown className={`icon-details ${dropdown ? "icon-details-rotate" : ''}`} />
                                    </div>
                                    {dropdown &&
                                        <div className='w-100'>
                                            <div className='d-flex flex-row justify-content-between mt-3'>
                                                <div>
                                                    <span className='letterDetails'>Max length</span>
                                                </div>
                                                <div>
                                                    <span className='boxDetails'>{rangeLength}ft</span>
                                                </div>
                                            </div>
                                            <input type="range" className='ranges' min="0" max="100" onChange={(event) => setRangeLength(event.target.value)} />
                                            <div className='d-flex flex-row justify-content-between mt-3'>
                                                <div>
                                                    <span className='letterDetails'>Max weight</span>
                                                </div>
                                                <div>
                                                    <span className='boxDetails'>{rangeWeight}kg</span>
                                                </div>
                                            </div>
                                            <input type="range" className='ranges' min="0" max="100" onChange={(event) => setRangeWeight(event.target.value)} />
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                    <div className='d-flex flex-row justify-content-between align-items-center w-100'>
                        <button className='btn-modalCalcu w-100' type='submit' onClick={submitCalculate} >CALCULATE ROUTE</button>
                    </div>
                {/* </form> */}
            </Modal>
        </>
    )
}

export default OverlayDetails