import React, { useEffect, useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useHistory, useParams } from 'react-router-dom';

const GeneralTabs = (props) => {

    const { idSettings } = useParams();
    const history = useHistory();
    const [activeTabs, setActiveTabs] = useState(props.activeTab);

    useEffect(() => {
        if (!idSettings) {
            history.push(`/settings/${props.activeTab}`);
        }
    }, []);

    const toggle = tab => {
        if (idSettings !== tab) {
            history.push(`/settings/${tab}`);
            setActiveTabs(tab)
        }
    }

    
    return (
        <Tabs
            defaultActiveKey={activeTabs}
            className='tabsContent'
            fill
            onSelect={(e) => toggle(e)}
        >
            {Object.entries(props.data).map((item) =>
                <Tab title={item[1].title} key={item[0]} eventKey={item[0]} >
                    {item[1].child}
                </Tab>
            )}
        </Tabs>
    )
}

export default GeneralTabs