import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
	FiArrowDownRight,
	FiArrowUpRight,
	FiCircle,
	FiTruck,
} from "react-icons/fi";
import SearchableDropdown from "../../../components/SearchableDropdown";

const LaneCard = (props) => {
	const {
		data,
		index,
		loadSizes = false,
		position = "",
		onUpdateItem = false,
		onRemoveItem = false,
	} = props;

	const icon = {
		first: <FiArrowUpRight className="dragging-handle letterDeparture" />,
		last: <FiArrowDownRight className="dragging-handle letterDeparture" />,
		"": <FiCircle className="dragging-handle handle-icon" />,
	};

	const [hoverable, setHoverable] = useState(true);

	const setType = (type) => {
		data.lane_type = type;
		setHoverable(false);
		onUpdateItem(data, index);
	};

	const setLoadSize = ({ id, name }) => {
		data.lane_load_size = id;
		data.lane_load_size_name = name;
		onUpdateItem(data, index);
	};

	const setPoints = (item) => {
		data.lane_place = `${item.city}, ${item.state}`;
		data.lane_place_id = (item.place_id ?? "").replace("c00208", "");
		onUpdateItem(data, index);
	};

	return (
		<div key={index}>
			<SearchableDropdown
				value={data.lane_place}
				onUpdateValue={setType}
				onChange={setPoints}
				remove={onRemoveItem}
				index={index}
				spacing_top={'1.8rem'}
				itemClass={
					"contentDeparture mb-3 " +
					(hoverable ? "hoverable-button-content" : "")
				}
				hoverOnLane={position == ""}
				icon={icon[position]}
			/>
			{loadSizes && (
				<div className={styles.bar}>
					<span className="letterLoad">Load size</span>
					<div className="contentBorder me-2">
						{loadSizes.map((item) => (
							<div className="button-radio">
								<input
									type="radio"
									id={`${index} - ${item.pv_id}`}
									defaultChecked={item.pv_id == data.lane_load_size}
									name={`check_substitution_${index}`}
									onChange={(e) =>
										setLoadSize({
											id: item.pv_id,
											name: item.pv_name,
										})
									}
								/>
								<label
									className="btn btn-default"
									htmlFor={`${index} - ${item.pv_id}`}
								>
									{item.pv_name}
								</label>
							</div>
						))}
					</div>
					<button className="btn-clean">
						<FiTruck className="icon-FiTruck" />
					</button>
				</div>
			)}
		</div>
	);
};

const styles = {
	bar: "d-flex flex-row justify-content-between align-items-center mb-3",
};

export default LaneCard;
