import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    value: [],
    external: 0,
};
export const IndicatorsSlice = createSlice({
    name: "selected_indicator_list",
    initialState,
    reducers: {
        setSelectedIndicators: (state, action) => {
            state.value = [...state.value, action.payload];
        },

        setFromExternalAction: (state, action) => {
            state.external = action.payload;
        },


        updateSelectedIndicators: (state, action) => {
            let arr = state.value;
            let objIndex = arr.findIndex((obj => obj.id === action.payload.id));
            arr[objIndex] = action.payload;
            state.value = arr;
        },

        deleteSelectedIndicators: (state, action) => {
            const filtered = state.value.filter(function (el) { return el.id !== action.payload.id; });
            state.value = filtered;
        },
    },
});

export const { setSelectedIndicators, deleteSelectedIndicators, updateSelectedIndicators, setFromExternalAction } = IndicatorsSlice.actions;
export const selectSelectedIndicatorsState = (state) => state.selected_indicator_list.value;
export const selectExternalAction = (state) => state.selected_indicator_list.external;


export default IndicatorsSlice.reducer;
