import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { FiMoreVertical, FiPlus } from "react-icons/fi";
import { Link } from 'react-router-dom';
import Swal from "sweetalert2";
import { useHorizontalScroll } from '../../hooks/useHorizontalScroll';
import SmallModal from '../../components/modals/SmallModal';

const IndicatorsBar = () => {
    const scrollRef = useHorizontalScroll();

    const [showModalAdd, setShowModalAdd] = useState(false);
    const [showModalEdit, setShowModalEdit] = useState(false);
    const settingItem = [
        { id: 1, title: 'Folder 1', },
        { id: 2, title: 'Revenues', },
        { id: 3, title: 'Sales', },
        { id: 4, title: 'Employment', },
        { id: 5, title: 'Industrial prod.', },
        { id: 6, title: 'Inventories', },
        { id: 7, title: 'Imports', },
    ];

    const [ sectionTitle, setSectionTitle ] = useState('Sales');

    const getTitle = (e) => {
        setSectionTitle(e.target.textContent);
    }

    const alertRemove = () => {
        Swal.fire({
            title: 'Are you sure? ',
            text: "Do you want to remove this folder?",
            background: '#121A1F',
            showCancelButton: true,
            showDenyButton: false,
            reverseButtons: true,
            cancelButtonText: 'CANCEL',
            confirmButtonText: 'REMOVE',
        })
    };

  return (
    <div className='setting'>
    <div className='settingSearch'>
        <span style={{width: '205px'}} className='btnSettings'><span className='fw-bold'>Category</span> - {sectionTitle}</span>
        <div className='dropSettings'>
            <DropdownButton
                drop={'down'}
                title={<FiMoreVertical className='icon-route-planning' />}
            >
                <Dropdown.Item onClick={() => setShowModalEdit(!showModalEdit)}>Edit folder name</Dropdown.Item>
                <Dropdown.Item onClick={alertRemove}>Remove folder</Dropdown.Item>
            </DropdownButton>
        </div>

    </div>
    <div ref={scrollRef}  className='settingBar'>
        {settingItem.map((item) =>
            <div className='sectionSettings' key={item.id}>
                <Link to="/indicators" className='btnSettings text-decoration-none' onClick={(e) => getTitle(e)}>{item.title}</Link>
            </div >
        )}
    </div>
    <button className='btnBar' onClick={() => setShowModalAdd(!showModalAdd)}>Add folder
        <FiPlus className='btnSettings' />
    </button>
    <SmallModal showSmallModal={showModalAdd} setShowSmallModal={setShowModalAdd} title={'New folder'} letterBtn={'CREATE FOLDER'} subTitle={'Folder name'} buttons={false} titleAlert={'Created folder'} textAlert={'Now, you can save your settings'} />
    <SmallModal showSmallModal={showModalEdit} setShowSmallModal={setShowModalEdit} title={'Edit folder'} letterBtn={'UPDATE FOLDER'} subTitle={'Folder name'} buttons={false} titleAlert={'Changes saved'} textAlert={'Now, you can use this settings'}/>
</div>
  )
}

export default IndicatorsBar