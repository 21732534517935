import React from 'react';
import Table from 'react-bootstrap/Table';
import imgUser from "../../../app/images/user.png";
import facebook from "../../../app/images/facebook.png";
import instagram from "../../../app/images/instagram.png";
import linkedin from "../../../app/images/linkedin.png";
import twitter from "../../../app/images/twitter.png";
import { FiChevronRight, FiAlertCircle, FiPlus } from "react-icons/fi";
import { useSelector } from "react-redux";
import { selectUserDataState } from "../../../redux/user/UserSlice";
import { ProtectedRoute } from '../../../app/ProtectedRoute';
import { Link, useHistory, useParams } from 'react-router-dom';
import EditInfoUser from './EditInfoUser';

const InfoAccount = ({ routes }) => {
    let history = useHistory();
    let { idSettings } = useParams();
    const userInfo = useSelector(selectUserDataState);
    // console.log(routes);

    return (
        <>
            <div className='mt-3 mb-5 flex-system-content'>
                <div className="d-flex flex-row">
                    <div className="initial-element">Photo</div>
                    <div className="p-2 middle-element d-flex flex-row align-items-center">
                        <div className='img-user-settings'>
                            <img src={imgUser} alt="" />
                        </div>
                        <div className='multiLineLabel ms-3'>
                            <span className='text-settings'>The photo helps personalize your account</span>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-row">
                    <div className="initial-element">Name</div>
                    <div className="middle-element middle-element-text">
                        {userInfo?.user_name ?? '---'} {userInfo?.user_last_name ?? '---'}
                    </div>
                    <div className="final-element text-end">
                        <Link to={`/settings/account/name`}>
                            <FiChevronRight className='icon-settings' />
                        </Link>
                    </div>
                </div>
                <div className="d-flex flex-row">
                    <div className="initial-element">Account type</div>
                    <div className="middle-element middle-element-text">
                        Analyst
                    </div>
                    <div className="final-element text-end">
                        <FiChevronRight className='icon-settings' />
                    </div>
                </div>
                <div className="d-flex flex-row">
                    <div className="initial-element">Gender</div>
                    <div className="middle-element middle-element-text">
                        Male
                    </div>
                    <div className="final-element text-end">
                        <FiChevronRight className='icon-settings' />
                    </div>
                </div>
                <div className="d-flex flex-row">
                    <div className="initial-element">E-mail</div>
                    <div className="middle-element middle-element-text">
                        {userInfo.user_email ?? '---'}
                    </div>
                    <div className="final-element text-end">
                        <FiChevronRight className='icon-settings' />
                    </div>
                </div>
                <div className="d-flex flex-row">
                    <div className="initial-element">Password</div>
                    <div className="middle-element middle-element-text">
                        <div className="d-flex flex-row flex-warp justify-content-between">
                            <span>********</span>
                            <div className='d-flex flex-row flex-wrap align-items-center'>
                                <div>
                                    <span className='text-sett'>Last modification: 08/05/22</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="final-element text-end">
                        <FiChevronRight className='icon-settings' />
                    </div>
                </div>
                <div className="d-flex flex-row">
                    <div className="initial-element">Phone</div>
                    <div className="middle-element middle-element-text">
                        <div className="d-flex flex-row flex-warp justify-content-between">
                            <span>{userInfo.user_cellphone ?? '---'}</span>
                            <div className='d-flex flex-row flex-wrap align-items-center'>
                                <FiAlertCircle className="info-icon-dashboard" />
                                <span className='ms-2'>Must be verified</span>
                            </div>
                        </div>
                    </div>
                    <div className="final-element text-end">
                        <FiChevronRight className='icon-settings' />
                    </div>
                </div>
                <div className="d-flex flex-row">
                    <div className="initial-element">Location</div>
                    <div className="middle-element middle-element-text">
                        413 Russell Park, Los angeles, california
                    </div>
                    <div className="final-element text-end">
                        <FiChevronRight className='icon-settings' />
                    </div>
                </div>
                <div className="d-flex flex-row">
                    <div className="initial-element">Job information</div>
                    <div className="middle-element middle-element-text">
                        Owner operator, in efrouting
                    </div>
                    <div className="final-element text-end">
                        <FiChevronRight className='icon-settings' />
                    </div>
                </div>
                <div className="d-flex flex-row">
                    <div className="initial-element">Social media</div>
                    <div className="middle-element middle-element-text">
                        <div className='d-flex flex-row gap-3'>
                            <div className='contentSocial'>
                                <div>
                                    <img src={facebook} alt="" />
                                </div>
                                <div>
                                    <span className='fs-6 ms-2'>Facebook</span>
                                </div>
                            </div>
                            <div className='contentSocial'>
                                <div>
                                    <img src={instagram} alt="" />
                                </div>
                                <div>
                                    <span className='fs-6 ms-2'>Instagram</span>
                                </div>
                            </div>
                            <div className='contentSocial'>
                                <div>
                                    <img src={twitter} alt="" />
                                </div>
                                <div>
                                    <span className='fs-6 ms-2'>Twitter</span>
                                </div>
                            </div>
                            <div className='contentSocial'>
                                <div>
                                    <img src={linkedin} alt="" />
                                </div>
                                <div>
                                    <span className='fs-6 ms-2'>Linkedin</span>
                                </div>
                            </div>
                            <div className='contentSocial-add'>
                                <div>
                                    <FiPlus />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {routes.Routes.map((item, index) =>{
            console.log(item);
                <ProtectedRoute
                    path={item.path}
                    routes={item}
                    component={item.Component}
                    key={index}
                    exact={item.isExact} />
            })}
        </>
    )
}

export default InfoAccount