import React from 'react';
import { FiHelpCircle, FiUser, FiMap, FiDollarSign, FiGitCommit } from "react-icons/fi";
import { Search } from "react-feather";
import ef from "../../app/images/ef.png";
import Layout from '../../components/layout/Layout';

const index = () => {

  const contentCard = [
    {
      title: 'efRouting',
      subtitle: 'Basic articles that will help you make the most of efRouting',
      icon: <img src={ef} />
    },
    {
      title: 'Account, Profile and Network',
      subtitle: 'Learn how to manage your account and creative network',
      icon: <FiUser className='icon-help' />
    },
    {
      title: 'Dispatch plans',
      subtitle: 'Learn how to manage and book your dispatch plans',
      icon: <FiMap className='icon-help' />
    },
    {
      title: 'FiDollarSign',
      subtitle: 'How to create a paid plan...',
      icon: <FiDollarSign className='icon-help' />
    },
    {
      title: 'Lanes',
      subtitle: 'How to create a lanes...',
      icon: <FiGitCommit className='icon-help' />
    },
    {
      title: 'efDta',
      subtitle: 'How to use efData...',
      icon: <img src={ef} />
    },
    {
      title: 'FaQs',
      subtitle: 'Frecuently ask',
      icon: <FiHelpCircle className='icon-help' />
    },
  ]

  return (
    <Layout >
      <div className="d-flex flex-column flex-wrap flex-md-nowrap pt-3 pb-2 px-md-4">
        <h1 className="heading-h1 fw-bold">Help center<FiHelpCircle className="icon-h1 ms-2" /></h1>
        <p className="subheading-top">Here, you can administrate your routes</p>
        <div className='filterHelpBar pb-1 pt-0 d-flex flex-row'>
          <div className="flex-fill">
            <div className="form-group has-search h-100">
              <Search className="form-control-search mt-2 ms-3" />
              <input type="text" className='form-control inputSearchHelp h-100 border-0' placeholder="Enter your search term here..." />
            </div>
          </div>
          <div style={{ width: '162px' }}>
            <button className='searchButton w-100'>SEARCH</button>
          </div>
        </div>
        <div className='divider-3'></div>
        <div className="d-flex flex-row flex-wrap gap-3 cards-content-help">
          {contentCard.map((item) =>
            <div className="card p-2">
              <div className="card-body">
                <div className="d-flex flex-row">
                  <div>
                    {item.icon}
                  </div>
                  <div className='text-start ms-3'>
                    <p className='text-ef-white fw-bold mb-2 lh-1'>{item.title}</p>
                    <p className='text-sett mb-0'>{item.subtitle}</p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  )
}

export default index