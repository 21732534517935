import React from 'react';
import { Form } from 'react-bootstrap';

const CustomInput = ({ value, onChange, type, ...rest }) => {
    let options;
   if (rest?.options) {
     options = rest?.options.map((element, index) =>
        <option key={index} value={element}>{element}</option>
    );
   }

    switch (type) {
        case "text":
            return (
                <input className='form-control'
                    type={type}
                    placeholder={rest?.placeholder}
                    onChange={(e) => onChange(e.target.value)}
                    defaultValue={value}
                />

            );
        case "radio":
            return rest?.options.map((e) => (
                <Form.Check
                    key={e}
                    type={type}
                    label={e}
                    defaultValue={e}
                    onChange={(e) => onChange(e.target.value)}
                    checked={value === e}
                />
            ));
        case "select":
            return (
                <select className="form-select" onChange={(e) => onChange(e.target.value)} value={value}>
                    {options}
                </select>
            );
        default:
            return null;
    }
};

export default CustomInput;