import React, { useEffect, useState } from 'react';
import { Nav, Offcanvas, Tab } from 'react-bootstrap';
import ItemCostStimator from './ItemCostEstimator';
import ItemFuelPrices from './ItemFuelPrices';
import ItemVariableCost from './ItemVariableCost';
import { FiTool, FiDollarSign, FiActivity, FiTrendingUp, FiChevronsRight } from "react-icons/fi";
import { useScrollPosition } from '../../hooks/useScrollPosition';

const OffCanvasMenu = ({ show, handleShow }) => {

    const scroll = useScrollPosition();
    const [changeScroll, setChangeScroll] = useState(false);

    useEffect(() => {
      if (scroll > 100) {
          setChangeScroll(true);
      } else {
          setChangeScroll(false);
      }
    }, [scroll, changeScroll]);

    return (<>
        <button onClick={() => handleShow()} className={changeScroll ? "btn-calculator right-with-hover" : "btn-calculator"} >
            <FiTool className='icon-btn-calculator' />
        </button>
        <Offcanvas show={show} onHide={handleShow} placement="end" scroll={true} renderStaticNode className="modal-calculator">
            <Offcanvas.Header className='top-calculator pe-2'>
                <Offcanvas.Title className='d-flex flex-row flex-wrap align-items-center justify-content-between w-100'>
                    <div><FiTool className='icon-btn-calculator me-2' /> <span className='title-calculator'>Tools</span></div>
                    <button className="btn-clean text-white" onClick={() => handleShow()}>
                        <FiChevronsRight />
                    </button>
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="p-0">
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <div className='d-flex flex-row-reverse flex-wrap h-100'>
                        <div className='content-nav-calculator'>
                            <Nav variant="pills" className="flex-column h-100">
                                <Nav.Item>
                                    <Nav.Link eventKey="first"><FiDollarSign className='icon-btn-calculator' /></Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="second"><FiActivity className='icon-btn-calculator' /></Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="third"><FiTrendingUp className='icon-btn-calculator' /></Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>
                        <div className='content-tabs-calculator p-3' sm={9}>
                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    <ItemCostStimator />
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                    <ItemFuelPrices />
                                </Tab.Pane>
                                <Tab.Pane eventKey="third">
                                    <ItemVariableCost />
                                </Tab.Pane>
                            </Tab.Content>
                        </div>
                    </div>
                </Tab.Container>
            </Offcanvas.Body>
        </Offcanvas>
    </>);
}

export default OffCanvasMenu;