import React, { useEffect, useState } from 'react';
import RouteStatisticCard from './RouteStatisticCard';
import { FiChevronDown, FiArrowUpRight, FiMoreHorizontal, FiArrowDownRight, FiPlus, FiChevronUp } from "react-icons/fi";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { Collapse } from 'react-bootstrap';
import GeneralMap from '../../../components/GeneralMap';
import ChartComponent from '../../../components/ChartComponent';
import BenchmarkStatistical from './BenchmarkStatistical';
import Alerts from '../../../components/Alerts';
import SmallModal from '../../../components/modals/SmallModal';
import OverlayDetails from '../../../components/modals/OverlayDetails';
import CustomToolTip from '../../../components/layout/CustomToolTip';
import { useParams } from 'react-router-dom';
import requestRouteDetails from '../../../services/black_box/requestRouteDetails';
import { useSelector } from 'react-redux';
import { selectGuardianState } from '../../../redux/auth/GuardianSlice';
import requestUserRouteDetails from '../../../services/user_routes/requestUserRouteDetails';
import Swal from 'sweetalert2';
import { useHistory, useLocation } from "react-router-dom";

import requestUserRouteUpdate from '../../../services/user_routes/requestUserRouteUpdate';


const CalculatedRoute = () => {

    const default_details = useSelector((state) => state.route.default_details);

    const [openOne, setOpenOne] = useState(false);
    const [openTwo, setOpenTwo] = useState(false);
    const [openThree, setOpenThree] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [routeDetail, setRouteDetail] = useState();
    const [proposedRoute, setProposedRoute] = useState();
    const [totals, setTotals] = useState({});

    let { by, routeId } = useParams();
    const user_token = useSelector(selectGuardianState);

    let history = useHistory();
    let location = useLocation();

    useEffect(() => {
        let response;
        if (routeId) {
            const getData = async () => {
                response = by === 'user' ? await requestUserRouteDetails(routeId, user_token) : await requestRouteDetails(routeId, user_token);

                if (response.state != 200) {
                    return;
                }
                let data = { ...response.data };

                let lanes = [];
                data?.get_lanes?.map(e => {
                    let lane = { ...e };
                    lanes.push(lane);
                });
                data.get_lanes = lanes;
                setProposedRoute(data);
                setRouteDetail(data);
            }
            getData();
        }
    }, [routeId]);

    useEffect(()=> {
        let totals = {
            miles: 0,
            fuel_gal:0,
            driving_time:0,
            operative_time:0,
            co2_emission:0
        };
        const lanes = routeDetail?.get_lanes ?? [];
        for(let i = 0; i < lanes.length; i++) {
            totals.miles += parseFloat(lanes[i].lane_miles);
            totals.fuel_gal += parseFloat(lanes[i].lane_fuel_gal);
            totals.driving_time += parseFloat(lanes[i].lane_driving_time);
            totals.operative_time += parseFloat(lanes[i].lane_operative_time);
            totals.co2_emission += parseFloat(lanes[i].lane_co2_emission);
        }
       
        setTotals(totals);
    },[routeDetail]);

    const saveRouteName = async (name) => {

        let rout_name = name.urf_name;
        let data = { user_route_name: rout_name };

        let response = await requestUserRouteUpdate(data, user_token, routeId);
        if (response.state !== 200) {
            return Alerts('Error', response.message)
        }
        let { from } = location.state || { from: { pathname: "/route-planning" } };
        Swal.fire({
            title: 'Saved route',
            text: "your route was successfully saved",
            showCancelButton: true,
            showDenyButton: false,
            background: '#121A1F',
            reverseButtons: true,
            cancelButtonText: 'STAY ON THE ROUTE',
            confirmButtonText: 'NEW SEARCH',
        }).then((result) => {
            if (result.isConfirmed) {
                setTimeout(() => {
                    history.replace(from);
                }, 3000)
            }
        })
    }

    const dataCards = [
        {
            title: "Total profit",
            type: 1,
            values: '$' + (routeDetail?.route_average_profit?.toFixed(2) ?? 0),
            iconType: 1,
            description: [
                { value: '$1000', value_description: "above the national avg." },
            ],
            detail_info: {
                title: "Profit detail",
                type: 1,
                detail_info_values: [
                    { value: 100, value_description: "01 day" },
                    { value: 100, value_description: "01 day" },
                    { value: 100, value_description: "01 day" },
                    { value: 100, value_description: "01 day" },
                ],
            },
        },
        {
            title: "Total cost",
            type: 2,
            values: '$' + (routeDetail?.route_total_variable_cost?.toFixed(2) ?? 0),
            iconType: 0,
            description: [
                { value: '-$1000', value_description: "fuel" },
                { value: '-%100', value_description: "tolls" },
            ],
            detail_info: {
                title: "Cost detail",
                type: 2,
                detail_info_values: [
                    { value: 100, value_description: "01 day" },
                    { value: 100, value_description: "01 day" },
                    { value: 100, value_description: "01 day" },
                    { value: 100, value_description: "01 day" },
                ],
            },
        },
        {
            title: "Total mileage",
            type: null,
            values: Math.round(totals?.miles ?? 0) + " mi",
            iconType: 3,
            description: [{ value: (totals?.operative_time?.secondsToDays() ?? 0) + "d", value_description: "operative time" }],
            detail_info: {
                title: "Mileage",
                type: null,
                detail_info_values: [
                    { value: 100, value_description: "01 day" },
                    { value: 100, value_description: "01 day" },
                    { value: 100, value_description: "01 day" },
                    { value: 100, value_description: "01 day" },
                ],
            },
        },
        {
            title: "Total emissions (co2)",
            type: null,
            values: Math.round(totals?.co2_emission ?? 0) + " kgs",
            iconType: 1,
            description: [
                { value: "1000 kgs", value_description: "above the nation avg." },
            ],
            detail_info: {
                title: "Emissions (co2) detail",
                type: null,
                detail_info_values: [
                    { value: 100, value_description: "01 day" },
                    { value: 100, value_description: "01 day" },
                    { value: 100, value_description: "01 day" },
                    { value: 100, value_description: "01 day" },
                ],
            },
        },
    ];

    const dataTableAnalysis = [
        { origin: 'Los Angeles', destination: 'San Francisco', _24_hrs_loading_chances: 380, _48_hrs_loading_chances: 58, connectivity_index: 'Loaded', ideal_connection: 7.6, custom: 9.6 },
        { origin: 'Los Angeles', destination: 'San Francisco', _24_hrs_loading_chances: 380, _48_hrs_loading_chances: 58, connectivity_index: 'Loaded', ideal_connection: 7.6, custom: 9.6 },

    ];

    const dataTableCost = [
        { origin: 'Los Angeles', destination: 'San Francisco', fuel_cost: 380, tolls: 58, total_variable_cost: 80, custom_cost: '-', },
        { origin: 'Los Angeles', destination: 'San Francisco', fuel_cost: 380, tolls: 58, total_variable_cost: 80, custom_cost: '-', },

    ];

    const dataTableProfit = [
        { origin: 'Los Angeles', destination: 'San Francisco', income_25_low: 380, income_average: 58, income_25_high: 80, gross_profit_25_low: 25, gross_profit_average: 555, gross_profit_25_high: 665 },
        { origin: 'Los Angeles', destination: 'San Francisco', income_25_low: 380, income_average: 58, income_25_high: 80, gross_profit_25_low: 25, gross_profit_average: 555, gross_profit_25_high: 665 },

    ];

    const info = [{
        x: new Date(1538778600000),
        y: [6629.81, 6650.5, 6623.04, 6633.33]
    },
    {
        x: new Date(1538780400000),
        y: [6632.01, 6643.59, 6620, 6630.11]
    },
    {
        x: new Date(1538782200000),
        y: [6630.71, 6648.95, 6623.34, 6635.65]
    },
    {
        x: new Date(1538784000000),
        y: [6635.65, 6651, 6629.67, 6638.24]
    },
    {
        x: new Date(1538785800000),
        y: [6638.24, 6640, 6620, 6624.47]
    },
    ];
    const chartOptions = {
        chart: {
            // type: type,
            background: 'transparent',
            toolbar: {
                show: false
            },
        },
        plotOptions: {
            candlestick: {
                colors: {
                    upward: '#209E59',
                    downward: '#3E4D6B'
                },
            }
        },
        dataLabels: {
            enabled: false,
        },
        yaxis: {
            labels: {
                formatter: function (y) {
                    return y?.toFixed(0) + "%";
                }
            }
        },
        xaxis: {
            type: 'datetime',
        }
    };

    const mapIsReadyCallback = (map) => {
        // console.log(map);
    };
    // console.table(dataTable);
    return (<>
        <p className="subheading-title"> <span className='fw-bold'>Results</span> - Route </p>
        <div className="row mb-3 gx-2">
            {dataCards.map((item) =>
                <div className="col-md-3 card-stat" key={item.title}>
                    <RouteStatisticCard data={item} />
                </div>
            )}
        </div>
        <div className="col-12">
            <div className="row mb-3 gx-2">
                <div className="col-md-4">
                    <GeneralMap mapIsReadyCallback={mapIsReadyCallback} />
                </div>
                <div className="col-md-8 pe-2">
                    <div className="card card-graph-bg h-100">
                        <div className="card-body p-4">
                            <div className="d-flex flex-row flex-wrap justify-content-between">
                                <div>
                                    <p className='card-title-routes fw-bold mb-0'>The lanes</p>
                                </div>
                                <div className='dropSettings'>
                                    <DropdownButton
                                        drop={'down'}
                                        title={<FiMoreHorizontal className='icon-route-planning' />}
                                        >
                                        <Dropdown.Item>View operative data</Dropdown.Item>
                                        <Dropdown.Item>View financial data</Dropdown.Item>
                                    </DropdownButton>
                                </div>
                            </div>
                            <div className="divider-3"></div>
                            <div className="d-flex flex-row flex-wrap gap-3">
                                <p className="subheading-top">05/08/2022</p>
                                <p className="subheading-top">{routeDetail?.route_driving_time?.secondsToDays() ?? 0} days en route</p>
                                <p className="subheading-top">{default_details?.rsd_trailer_type_name}</p>
                            </div>
                            <div className="table-responsive" style={{ height: '120px' }}>
                                <table className="table table-borderless results-table table-hover">
                                    <tbody>
                                        {routeDetail?.get_lanes.map((item, index) =>
                                            <tr className='my-2' key={index}>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                    <p className='route-name fw-bold mb-0'>{default_details?.rsd_load_size_name}</p>
                                                </td>
                                                <td>
                                                    <div className='d-flex flex-row flex-wrap align-items-center'>
                                                        <div className="icon-route-planning">
                                                            <FiArrowUpRight />
                                                        </div>
                                                        <div>
                                                            <p className='route-name fw-bold mb-0'>{item.lane_departure_city ?? '---'}</p>
                                                            <p className='route-desc mb-0'>{item.lane_departure_state ?? '---'}</p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='d-flex flex-row flex-wrap align-items-center'>
                                                        <div className="icon-route-planning">
                                                            <FiArrowDownRight />
                                                        </div>
                                                        <div>
                                                            <p className='route-name fw-bold mb-0'>{item.lane_destination_city ?? '---'}</p>
                                                            <p className='route-desc mb-0'>{item.lane_destination_state ?? '---'}</p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <p className='route-name fw-bold mb-0'>${item.lane_total_variable_cost?.toFixed(2) ?? 0}</p>
                                                        <p className='route-desc mb-0'>Cost</p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <p className='route-name fw-bold mb-0'>${item.lane_profit?.toFixed(2) ?? 0}</p>
                                                        <p className='route-desc mb-0'>Profit</p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <p className='route-name fw-bold mb-0'>{Math.round(item?.lane_miles ?? 0)}mi</p>
                                                        <p className='route-desc mb-0'>Miles</p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <p className='route-name fw-bold mb-0'>200 kgs</p>
                                                        <p className='route-desc mb-0'>Emissions (co2)</p>
                                                    </div>
                                                </td>
                                            </tr>

                                        )}
                                    </tbody>
                                </table>
                            </div>
                            <table className="table table-borderless results-table table-hover mb-0">
                                <tbody>
                                    <tr className='my-2 hoverable-td' onClick={() => setShowModal(!showModal)}>
                                        <td colSpan={6}>
                                            <div className='d-flex flex-row flex-wrap align-items-center'>
                                                <div className="icon-route-planning">
                                                    <FiPlus />
                                                </div>
                                                <div>
                                                    <p className='route-name fw-bold mb-0'>Custom route</p>
                                                    <p className='route-desc mb-0'>Modify the route</p>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <OverlayDetails show={showModal} setShow={setShowModal} calendar={false} setRoute={setRouteDetail} data={routeDetail?.get_lanes ?? []} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex flex-row flex-wrap mb-3">
                <div className="col-md-12 card card-graph-bg">
                    <div className="card-body p-3 pb-1 ps-4 card-hover">
                        <div className="d-flex flex-row flex-wrap align-items-center justify-content-between">
                            <p className="subheading-title fw-bold">Operative route analysis</p>
                            <button type="button"
                                aria-controls="one"
                                className="btn-clean text-white"
                                onClick={() => setOpenOne(!openOne)}
                            >
                                {!openOne ? <FiChevronDown /> : <FiChevronUp />}

                            </button>
                        </div>
                        <Collapse in={openOne}>
                            <div id="one">
                                <p className='route-name fw-bold mb-2'>Route description</p>
                                <table className="table table-sm table-bordered border-dark input-table-1">
                                    <thead>
                                        <tr className='text-thead fw-bold'>
                                            <th scope='col'>Origin</th>
                                            <th scope='col'>Destination</th>
                                            <th scope='col'>Miles</th>
                                            <th scope='col'>Fuel</th>
                                            <th scope='col'>Trip Type</th>
                                            <th scope='col'>Driving Time</th>
                                            <th scope='col'>Operative Time</th>
                                            <th scope='col'>Emissions Co2</th>
                                            <th scope='col'>Size/Weight</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {routeDetail?.get_lanes.map((i, index) => <tr className='text-tbody' key={index}>
                                            <td>
                                                <CustomToolTip content={i?.lane_departure_city ?? '---'} direction="top">
                                                    <input className='form-control-plaintext p-0 lh-1 text-tbody' type="text" defaultValue={i?.lane_departure_city ?? ''} name={`route_description.${index}.lane_departure_city`} />
                                                </CustomToolTip>
                                            </td>
                                            <td>
                                                <CustomToolTip content={i?.lane_destination_city ?? '---'} direction="top">
                                                    <input className='form-control-plaintext p-0 lh-1 text-tbody' type="text" defaultValue={i?.lane_destination_city ?? ''} name={`route_description.${index}.lane_destination_city`} />
                                                </CustomToolTip>
                                            </td>
                                            <td>
                                                <CustomToolTip content={i?.lane_miles ?? 0} direction="top">
                                                    <input className='form-control-plaintext p-0 lh-1 text-tbody' type="text" defaultValue={Math.round(i?.lane_miles ?? 0)} name={`route_description.${index}.lane_miles`} />
                                                </CustomToolTip>
                                            </td>
                                            <td>
                                                <CustomToolTip content={i?.lane_fuel_gal ?? 0} direction="top">
                                                    <input className='form-control-plaintext p-0 lh-1 text-tbody' type="text" defaultValue={i?.lane_fuel_gal?.toFixed(2) ?? ''} name={`route_description.${index}.lane_fuel_gal`} />
                                                </CustomToolTip>
                                            </td>
                                            <td>
                                                <CustomToolTip content={i?.lane_trip_type_name ?? '---'} direction="top">
                                                    <input className='form-control-plaintext p-0 lh-1 text-tbody' type="text" defaultValue={i?.lane_trip_type_name ?? ''} name={`route_description.${index}.lane_trip_type`} />
                                                </CustomToolTip>
                                            </td>
                                            <td>
                                                <CustomToolTip content={i?.lane_driving_time?.secondsToHours() + 'hr' ?? 0} direction="top">
                                                    <input className='form-control-plaintext p-0 lh-1 text-tbody' type="text" defaultValue={i?.lane_driving_time?.secondsToHours() + 'hr' ?? ''} name={`route_description.${index}.lane_driving_time`} />
                                                </CustomToolTip>
                                            </td>
                                            <td>
                                                <CustomToolTip content={i?.lane_operative_time?.secondsToHours() + 'hr' ?? 0} direction="top">
                                                    <input className='form-control-plaintext p-0 lh-1 text-tbody' type="text" defaultValue={i?.lane_operative_time?.secondsToHours() + 'hr' ?? ''} name={`route_description.${index}.lane_operative_time`} />
                                                </CustomToolTip></td>
                                            <td>
                                                <CustomToolTip content={i?.lane_co2_emission?.toFixed(2)} direction="top">
                                                    <input className='form-control-plaintext p-0 lh-1 text-tbody' type="text" name={`route_description.${index}.custom`} defaultValue={i?.lane_co2_emission?.toFixed(2)} />
                                                </CustomToolTip>
                                            </td>
                                            <td>
                                                <CustomToolTip content={'---'} direction="top">
                                                    <input className='form-control-plaintext p-0 lh-1 text-tbody' type="text" name={``} defaultValue={'--'} />
                                                </CustomToolTip>
                                            </td>
                                        </tr>)}

                                        <tr className='text-tbody bg-tbody-success'>
                                            <td colSpan={2}>Total proposed route</td>
                                            <td>
                                                <CustomToolTip content={proposedRoute?.route_miles + 'mi' ?? 0} direction="top">
                                                    {proposedRoute?.route_miles?.toFixed(2) + 'mi' ?? 0}
                                                </CustomToolTip>
                                            </td>
                                            <td>
                                                <CustomToolTip content={proposedRoute?.route_fuel ?? 0} direction="top">
                                                    {proposedRoute?.route_fuel?.toFixed(2) ?? '---' + 'gl'}
                                                </CustomToolTip>
                                            </td>
                                            <td>
                                                <CustomToolTip content={'---'} direction="top">
                                                    {'---'}
                                                </CustomToolTip>
                                            </td>
                                            <td>
                                                <CustomToolTip content={proposedRoute?.route_driving_time?.secondsToHours() + 'hr' ?? 0} direction="top">
                                                    {proposedRoute?.route_driving_time?.secondsToHours() + 'hr' ?? 0}
                                                </CustomToolTip>
                                            </td>
                                            <td>
                                                <CustomToolTip content={proposedRoute?.route_operative_time?.secondsToHours() + 'hr' ?? 0} direction="top">
                                                    {proposedRoute?.route_operative_time?.secondsToHours() + 'hr' ?? 0}
                                                </CustomToolTip>
                                            </td>
                                            <td>
                                                <CustomToolTip content={proposedRoute?.route_co2_emission + 'kg' ?? 0} direction="top">
                                                    {proposedRoute?.route_co2_emission?.toFixed(2) + 'kg' ?? 0}
                                                </CustomToolTip>
                                            </td>
                                            <td>
                                                <CustomToolTip content={'---'} direction="top">
                                                    {'---'}
                                                </CustomToolTip>
                                            </td>
                                        </tr>

                                        <tr className='text-tbody'>
                                            <td colSpan={2}>Total custom</td>
                                            <td>
                                                <CustomToolTip content={totals?.miles ?? 0} direction="top">
                                                    {totals?.miles?.toFixed(2) ?? '---' + 'mi'}
                                                </CustomToolTip>
                                            </td>
                                            <td>
                                                <CustomToolTip content={totals?.fuel_gal ?? 0} direction="top">
                                                    {totals?.fuel_gal?.toFixed(2) ?? '---' + 'gl'}
                                                </CustomToolTip>
                                            </td>
                                            <td>
                                                <CustomToolTip content={'---'} direction="top">
                                                    {'---'}
                                                </CustomToolTip>
                                            </td>
                                            <td>
                                                <CustomToolTip content={totals?.driving_time?.secondsToHours() ?? '---'} direction="top">
                                                    {totals?.driving_time?.secondsToHours() + 'hr'}
                                                </CustomToolTip>
                                            </td>
                                            <td>
                                                <CustomToolTip content={totals?.operative_time?.secondsToHours() ?? '---'} direction="top">
                                                    {totals?.operative_time?.secondsToHours() + 'hr' }
                                                </CustomToolTip>
                                            </td>
                                            <td>
                                                <CustomToolTip content={totals?.co2_emission ?? '---'} direction="top">
                                                    {Math.round(totals?.co2_emission ?? 0) + 'kg'}
                                                </CustomToolTip>
                                            </td>
                                            <td>
                                                <CustomToolTip content={'---'} direction="top">
                                                    {'---'}
                                                </CustomToolTip>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <p className='route-name fw-bold mb-2'>Indicators analysis</p>
                                <table className="table table-sm table-bordered border-dark">
                                    <thead>
                                        <tr className='text-thead fw-bold'>
                                            <th scope='col'>Origin</th>
                                            <th scope='col'>Destination</th>
                                            <th scope='col'>24 hrs loading chances</th>
                                            <th scope='col'>48 hrs loading chances</th>
                                            <th scope='col'>Connectivity index</th>
                                            <th scope='col'>Ideal connection</th>
                                            <th scope='col'>Custom</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dataTableAnalysis.map((i, index) => <tr className='text-tbody' key={index}>
                                            <td>
                                                <CustomToolTip content={i.origin} direction="top">
                                                    <input className='form-control-plaintext p-0 lh-1 text-tbody' type="text" defaultValue={i?.origin ?? ''} name={`indicators_analysis.${index}.origin`} />
                                                </CustomToolTip>
                                            </td>
                                            <td>
                                                <CustomToolTip content={i.destination} direction="top">
                                                    <input className='form-control-plaintext p-0 lh-1 text-tbody' type="text" defaultValue={i?.destination ?? ''} name={`indicators_analysis.${index}.destination`} />
                                                </CustomToolTip>
                                            </td>
                                            <td>
                                                <CustomToolTip content={i._24_hrs_loading_chances} direction="top">
                                                    <input className='form-control-plaintext p-0 lh-1 text-tbody' type="text" defaultValue={i?._24_hrs_loading_chances ?? ''} name={`indicators_analysis.${index}._24_hrs_loading_chances`} />
                                                </CustomToolTip>
                                            </td>
                                            <td>
                                                <CustomToolTip content={i._48_hrs_loading_chances} direction="top">
                                                    <input className='form-control-plaintext p-0 lh-1 text-tbody' type="text" defaultValue={i?._48_hrs_loading_chances ?? ''} name={`indicators_analysis.${index}._48_hrs_loading_chances`} />
                                                </CustomToolTip>
                                            </td>
                                            <td>
                                                <CustomToolTip content={i.connectivity_index} direction="top">
                                                    <input className='form-control-plaintext p-0 lh-1 text-tbody' type="text" defaultValue={i?.connectivity_index ?? ''} name={`indicators_analysis.${index}.connectivity_index`} />
                                                </CustomToolTip>
                                            </td>
                                            <td>
                                                <CustomToolTip content={i.ideal_connection} direction="top">
                                                    <input className='form-control-plaintext p-0 lh-1 text-tbody' type="text" defaultValue={i?.ideal_connection ?? ''} name={`indicators_analysis.${index}.ideal_connection`} />
                                                </CustomToolTip>
                                            </td>
                                            <td>
                                                <CustomToolTip content={i.custom} direction="top">
                                                    <input className='form-control-plaintext p-0 lh-1 text-tbody' type="text" defaultValue={i?.custom ?? ''} name={`indicators_analysis.${index}.custom`} />
                                                </CustomToolTip>
                                            </td>
                                        </tr>)}
                                    </tbody>
                                </table>
                            </div>
                        </Collapse>
                    </div>
                </div>
            </div>
            <div className="d-flex flex-row flex-wrap mb-3">
                <div className="col-md-12 card card-graph-bg">
                    <div className="card-body p-3 pb-1 ps-4 card-hover">
                        <div className="d-flex flex-row flex-wrap align-items-center justify-content-between">
                            <p className="subheading-title fw-bold">Financial route analysis</p>
                            <button type="button"
                                aria-controls="one"
                                className="btn-clean text-white"
                                onClick={() => setOpenTwo(!openTwo)}
                            >
                                {!openTwo ? <FiChevronDown /> : <FiChevronUp />}

                            </button>
                        </div>
                        <Collapse in={openTwo}>
                            <div id="two">
                                <p className='route-name fw-bold mb-2'>Cost</p>
                                <table className="table table-sm table-bordered border-dark">
                                    <thead>
                                        <tr className='text-thead fw-bold'>
                                            <th scope='col'>Origin</th>
                                            <th scope='col'>Destination</th>
                                            <th scope='col'>Fuel cost</th>
                                            <th scope='col'>Tolls</th>
                                            <th scope='col'>Total variable cost</th>
                                            <th scope='col'>Custom cost</th>
                                            <th scope='col'>Custom cost</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {dataTableCost.map((i, index) => <tr className='text-tbody' key={index}>
                                            <td>
                                                <CustomToolTip content={i.origin} direction="top">
                                                    <input className='form-control-plaintext p-0 lh-1 text-tbody' type="text" defaultValue={i?.origin ?? ''} name={`financial_cost.${index}.origin`} />
                                                </CustomToolTip>
                                            </td>
                                            <td>
                                                <CustomToolTip content={i.destination} direction="top">
                                                    <input className='form-control-plaintext p-0 lh-1 text-tbody' type="text" defaultValue={i?.destination ?? ''} name={`financial_cost.${index}.destination`} />
                                                </CustomToolTip>
                                            </td>
                                            <td>
                                                <CustomToolTip content={i.fuel_cost} direction="top">
                                                    <input className='form-control-plaintext p-0 lh-1 text-tbody' type="text" defaultValue={i?.fuel_cost ?? ''} name={`financial_cost.${index}.fuel_cost`} />
                                                </CustomToolTip>
                                            </td>
                                            <td>
                                                <CustomToolTip content={i.tolls} direction="top">
                                                    <input className='form-control-plaintext p-0 lh-1 text-tbody' type="text" defaultValue={i?.tolls ?? ''} name={`financial_cost.${index}.tolls`} />
                                                </CustomToolTip>
                                            </td>
                                            <td>
                                                <CustomToolTip content={i.total_variable_cost} direction="top">
                                                    <input className='form-control-plaintext p-0 lh-1 text-tbody' type="text" defaultValue={i?.total_variable_cost ?? ''} name={`financial_cost.${index}.total_variable_cost`} />
                                                </CustomToolTip>
                                            </td>
                                            <td>
                                                <CustomToolTip content={i.custom_cost} direction="top">
                                                    <input className='form-control-plaintext p-0 lh-1 text-tbody' type="text" defaultValue={i?.custom_cost ?? ''} name={`financial_cost.${index}.custom_cost`} />
                                                </CustomToolTip>
                                            </td>
                                            <td>
                                                <CustomToolTip content={'---'} direction="top">
                                                    <input className='form-control-plaintext p-0 lh-1 text-tbody' type="text" defaultValue={'---'} name={``} />
                                                </CustomToolTip>
                                            </td>
                                        </tr>)}
                                        <tr className='text-tbody bg-tbody-success'>
                                            <td colSpan={2}>Total proposed route</td>
                                            <td>
                                                <CustomToolTip content={'$880'} direction="top">
                                                    {'$880'}
                                                </CustomToolTip>
                                            </td>
                                            <td>
                                                <CustomToolTip content={'$135'} direction="top">
                                                    {'$135'}
                                                </CustomToolTip>
                                            </td>
                                            <td>
                                                <CustomToolTip content={'$100'} direction="top">
                                                    {'$100'}
                                                </CustomToolTip>
                                            </td>
                                            <td>
                                                <CustomToolTip content={'-'} direction="top">
                                                    {'-'}
                                                </CustomToolTip>
                                            </td>
                                            <td>
                                                <CustomToolTip content={'-'} direction="top">
                                                    {'-'}
                                                </CustomToolTip>
                                            </td>
                                        </tr>

                                        <tr className='text-tbody'>
                                            <td colSpan={2}>Total custom</td>
                                            <td>
                                                <CustomToolTip content={'$880'} direction="top">
                                                    {'$880'}
                                                </CustomToolTip>
                                            </td>
                                            <td>
                                                <CustomToolTip content={'$135'} direction="top">
                                                    {'$135'}
                                                </CustomToolTip>
                                            </td>
                                            <td>
                                                <CustomToolTip content={'$100'} direction="top">
                                                    {'$100'}
                                                </CustomToolTip>
                                            </td>
                                            <td>
                                                <CustomToolTip content={'-'} direction="top">
                                                    {'-'}
                                                </CustomToolTip>
                                            </td>
                                            <td>
                                                <CustomToolTip content={'-'} direction="top">
                                                    {'-'}
                                                </CustomToolTip>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <p className='route-name fw-bold mb-2'>Profit</p>
                                <table className="table table-sm table-bordered border-dark">
                                    <thead>
                                        <tr className='text-super-thead fw-bold'>
                                            <th colSpan={2}> </th>
                                            <th colSpan={3}>Income</th>
                                            <th colSpan={3}>Gross profit</th>
                                        </tr>
                                        <tr className='text-thead fw-bold'>
                                            <th scope='col'>Origin</th>
                                            <th scope='col'>Destination</th>
                                            <th scope='col'>25 % low</th>
                                            <th scope='col'>Average</th>
                                            <th scope='col'>25 % high</th>
                                            <th scope='col'>25 % low</th>
                                            <th scope='col'>Average</th>
                                            <th scope='col'>25 % high</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dataTableProfit.map((i, index) => <tr className='text-tbody' key={index}>
                                            <td>
                                                <CustomToolTip content={i.origin} direction="top">
                                                    <input className='form-control-plaintext p-0 lh-1 text-tbody' type="text" defaultValue={i?.origin ?? ''} name={`financial_profit.${index}.origin`} />
                                                </CustomToolTip>
                                            </td>
                                            <td>
                                                <CustomToolTip content={i.destination} direction="top">
                                                    <input className='form-control-plaintext p-0 lh-1 text-tbody' type="text" defaultValue={i?.destination ?? ''} name={`financial_profit.${index}.destination`} />
                                                </CustomToolTip>
                                            </td>
                                            <td>
                                                <CustomToolTip content={i.income_25_low} direction="top">
                                                    <input className='form-control-plaintext p-0 lh-1 text-tbody' type="text" defaultValue={i?.income_25_low ?? ''} name={`financial_profit.${index}.income_25_low`} />
                                                </CustomToolTip>
                                            </td>
                                            <td>
                                                <CustomToolTip content={i.income_average} direction="top">
                                                    <input className='form-control-plaintext p-0 lh-1 text-tbody' type="text" defaultValue={i?.income_average ?? ''} name={`financial_profit.${index}.income_average`} />
                                                </CustomToolTip>
                                            </td>
                                            <td>
                                                <CustomToolTip content={i.income_25_high} direction="top">
                                                    <input className='form-control-plaintext p-0 lh-1 text-tbody' type="text" defaultValue={i?.income_25_high ?? ''} name={`financial_profit.${index}.income_25_high`} />
                                                </CustomToolTip>
                                            </td>
                                            <td>
                                                <CustomToolTip content={i.gross_profit_25_low} direction="top">
                                                    <input className='form-control-plaintext p-0 lh-1 text-tbody' type="text" defaultValue={i?.gross_profit_25_low ?? ''} name={`financial_profit.${index}.gross_profit_25_low`} />
                                                </CustomToolTip>
                                            </td>
                                            <td>
                                                <CustomToolTip content={i.gross_profit_average} direction="top">
                                                    <input className='form-control-plaintext p-0 lh-1 text-tbody' type="text" defaultValue={i?.gross_profit_average ?? ''} name={`financial_profit.${index}.gross_profit_average`} />
                                                </CustomToolTip>
                                            </td>
                                            <td>
                                                <CustomToolTip content={i.gross_profit_25_high} direction="top">
                                                    <input className='form-control-plaintext p-0 lh-1 text-tbody' type="text" defaultValue={i?.gross_profit_25_high ?? ''} name={`financial_profit.${index}.gross_profit_25_high`} />
                                                </CustomToolTip>
                                            </td>
                                        </tr>)}
                                    </tbody>
                                </table>
                            </div>
                        </Collapse>
                    </div>
                </div>
            </div>
            <div className="d-flex flex-row flex-wrap mb-4">
                <div className="col-md-12 card card-graph-bg">
                    <div className="card-body p-3 ps-4 card-hover">
                        <div className="d-flex flex-row flex-wrap align-items-center justify-content-between">
                            <p className="subheading-title fw-bold mb-0">Benchmark</p>
                            {
                                openThree &&
                                <div className='d-flex flex-row flex-wrap col-9 justify-content-between'>
                                    <div className='d-flex flex-row flex-wrap align-items-center benchmarking-item'>
                                        <div className='me-2'><FiChevronDown className='btn-clean text-name-cargo icon-sidebar-drop' /></div>
                                        <div>
                                            <p className='text-name-cargo fw-bold mb-0'>Profit per mile:</p>
                                            <p className='text-name-cargo mb-0'>Current route</p>
                                        </div>
                                    </div>
                                    <div className='d-flex flex-row flex-wrap align-items-center benchmarking-item'>
                                        <div className='me-2'><FiChevronDown className='btn-clean text-name-cargo icon-sidebar-drop' /></div>
                                        <div>
                                            <p className='text-name-cargo fw-bold mb-0'>Revenue per mile: </p>
                                            <p className='text-name-cargo mb-0'>Current route</p>
                                        </div>
                                    </div>
                                    <div className='d-flex flex-row flex-wrap align-items-center benchmarking-item'>
                                        <div className='me-2'><FiChevronDown className='btn-clean text-name-cargo icon-sidebar-drop' /></div>
                                        <div>
                                            <p className='text-name-cargo fw-bold mb-0'>Emissions per mile: </p>
                                            <p className='text-name-cargo mb-0'>Current route</p>
                                        </div>
                                    </div>
                                    <div className='d-flex flex-row flex-wrap align-items-center benchmarking-item'>
                                        <div className='me-2'><FiChevronDown className='btn-clean text-name-cargo icon-sidebar-drop' /></div>
                                        <div>
                                            <p className='text-name-cargo fw-bold mb-0'>Miles per day: </p>
                                            <p className='text-name-cargo mb-0'>Current route</p>
                                        </div>
                                    </div>
                                </div>
                            }
                            <button type="button"
                                aria-controls="one"
                                className="btn-clean text-white"
                                onClick={() => setOpenThree(!openThree)}
                            >
                                {!openThree ? <FiChevronDown /> : <FiChevronUp />}

                            </button>
                        </div>
                        <Collapse in={openThree}>
                            <div id="three">
                                <div className="d-flex flex-row flex-wrap">
                                    <div className="col-md-7">
                                        <ChartComponent data={info} chartOptions={chartOptions} type={'candlestick'} height={"100%"} />
                                    </div>
                                    <div className="col-md-5">
                                        <div className="d-flex flex-row flex-wrap">
                                            <div className="col-md-6 p-3">
                                                <BenchmarkStatistical title={'Your average profit is'} value={200.12} iconType={1} value_description={'Higher than the national'} />
                                            </div>
                                            <div className="col-md-6 p-3">
                                                <BenchmarkStatistical title={'Your 25% high is'} value={200.12} iconType={2} value_description={'Lower than the national'} />

                                            </div>
                                            <div className="col-md-6 p-3">
                                                <BenchmarkStatistical title={'Your average profit is'} value={200.12} iconType={1} value_description={'Lower than the national'} />

                                            </div>
                                            <div className="col-md-6 p-3">
                                                <BenchmarkStatistical title={'Your overall performance range between the'} value={200.12} iconType={3} value_description={'Of the national'} />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Collapse>
                    </div>
                </div>
            </div>
            <div className="d-flex flex-row flex-wrap justify-content-end mb-4 gap-2">
                <button type="button" className="btn btn-outline-success btn-route-lg">BOOK ROUTE</button>
                <button type="button" className="btn btn-outline-success btn-route-lg" onClick={() => setOpenModal(!openModal)}>SAVE TRIP</button>
            </div>
        </div>
        <SmallModal actionModal={saveRouteName} showSmallModal={openModal} setShowSmallModal={setOpenModal} title={'Save As'} subTitle={'Route name'} letterBtn={'SAVE'} buttons={true} />

    </>);
}

export default CalculatedRoute;