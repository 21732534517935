import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { FiX, FiChevronDown, FiCalendar, FiArrowUpRight } from "react-icons/fi";
import { useHistory, useLocation } from 'react-router-dom';
import SaveSettings from './SaveSettings';
import Calendar from 'moedim';
import styled from 'styled-components';
import SearchableDropdown from '../SearchableDropdown';
import { useDispatch } from 'react-redux';
import { setBBRoutes } from "../../redux/blackBoxRoutes/blackBoxRoutesSlice";
import requestRoutes from '../../services/black_box/requestRoutes';

const EditSettings = ({ showEditSettings, setShowEditSettings }) => {
    const dispatch = useDispatch();

    const handleClose = () => setShowEditSettings(false);
    const [dropdown, setDropdown] = useState(false);

    const [rangeLength, setRangeLength] = useState(50);
    const [rangeWeight, setRangeWeight] = useState(50);
    const [modalSettings, setModalSettings] = useState(false);
    const [dateValue, setDateValue] = useState(new Date());
    const [dateDropdown, setDateDropdown] = useState(false);
    const [departure, setDeparture] = useState();
    const [destination, setDestination] = useState();

    const history = useHistory();
    let location = useLocation();
    const StyledCalendar = styled(Calendar)`
        --moedim-primary: #1c8c4f;
        width: 260px;
        border: 1px solid #364752;
        margin-top: -1.1rem;
        color: #cacaca;
        border-radius: 16px;
        background: transparent;
        background: #121A1F;
        padding: 24px 24px 24px 15px;
        position: absolute;
        z-index: 99;
    `;

    const formatDate = () => {
        var date = dateValue.toLocaleDateString("en-US");
        return date;
    }

    let { from } = location.state || { from: { pathname: "/route-planning/results" } };

    const search = async () => {
        let params = [];
        departure && (params = [{ key: 'departure_state_name', value: departure.state }, { key: 'departure_city_name', value: departure.city }, { key: 'departure_city_place_id', value: departure.place_id }]);
        destination && (params = [{ key: 'destination_state_name', value: destination.state }, { key: 'destination_city_name', value: destination.city }, { key: 'destination_city_place_id', value: destination.place_id }]);
        let response = await requestRoutes(params);
        if (response.state != 200) {
            return;
        }
        let data = response.data ?? [];
        dispatch(setBBRoutes(data));
        history.replace(from);
    }

    return (
        <>
            <Modal show={showEditSettings} onHide={handleClose}
                centered
                dialogClassName="modal-80w"
                className='modalDetails'
            >
                <Modal.Header>
                    <Modal.Title>Edit settings</Modal.Title>
                    <FiX className='btn-closeModal' onClick={handleClose} />
                </Modal.Header>
                <div className={styles.contentDiv} >
                    <div className='col-md-6'>
                        <p className='letterModal'>Departure</p>
                        <SearchableDropdown onChange={(item) => setDeparture(item)} inputClass={'form-control'} spacing_top={'0rem'} />
                    </div>
                    <div className='col-md-6'>
                        <p className='letterModal'>Destination</p>
                        <SearchableDropdown onChange={(item) => setDestination(item)} inputClass={'form-control'} spacing_top={'0rem'} />
                    </div>
                    <div className='col-md-12 mt-3'>
                        <p className='letterModal'>When</p>
                    </div>
                    <div className={styles.contentDiv} id="formDate">
                        <div onClick={() => setDateDropdown(!dateDropdown)} className="dateDrop w-100">
                            <FiCalendar className='letterDeparture' />
                            <span className='letterDeparture'>{formatDate()}</span>
                        </div>
                    </div>
                    {dateDropdown &&
                        <div className="col-md-12" htmlFor="formDate">
                            <StyledCalendar className='calendar' value={dateValue} onChange={(d) => setDateValue(d)} />
                        </div>}
                    <div className='col-md-12 mt-3 mb-3'>
                        <p className='letterModal'>Details</p>
                        <div className={styles.contentDetails}>
                            <div className="btn-load-size w-100">
                                <input type="radio" id="a2" name="check-substitution-4" />
                                <label className="btn btn-default w-100" htmlFor="a2">FTL</label>
                            </div>
                            <div className="btn-load-size w-100">
                                <input type="radio" id="a4" name="check-substitution-4" />
                                <label className="btn btn-default w-100" htmlFor="a4">LTL</label>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 d-flex flex-column mt-3 w-100">
                        <span className='letterDetails mb-1'>Trailer type</span>
                        <select className="selectDetails w-100">
                            <option>Dry van</option>
                            <option>Reefer</option>
                            <option>Flaatbed</option>
                        </select>
                    </div>
                    <div className='col-md-12 mt-3 mb-3'>
                        <div onClick={() => setDropdown(!dropdown)} className="btnDetails w-100">
                            <span className='letterDetails'>Advance details</span>
                            <FiChevronDown className={`icon-details ${dropdown ? "icon-details-rotate" : ''}`} />
                        </div>
                        {dropdown &&
                            <div className='w-100'>
                                <div className={styles.contentDetails}>
                                    <div>
                                        <span className='letterDetails'>Max length</span>
                                    </div>
                                    <div>
                                        <span className='boxDetails'>{rangeLength}ft</span>
                                    </div>
                                </div>
                                <input type="range" className='ranges' min="0" max="100" onChange={(event) => setRangeLength(event.target.value)} />
                                <div className={styles.contentDetails}>
                                    <div>
                                        <span className='letterDetails'>Max weight</span>
                                    </div>
                                    <div>
                                        <span className='boxDetails'>{rangeWeight}kg</span>
                                    </div>
                                </div>
                                <input type="range" className='ranges' min="0" max="100" onChange={(event) => setRangeWeight(event.target.value)} />
                            </div>
                        }
                    </div>
                </div>
                <div className={styles.contentButton}>
                    <button className='btn-modalSave' onClick={() => setModalSettings(!modalSettings)}>SAVE SETTINGS</button>
                    <button className='btn-modalCalcu' onClick={() => history.replace(from)}>SEARCH</button>
                </div>
            </Modal>
            <SaveSettings showSaveSettings={modalSettings} setShowSaveSettings={setModalSettings} />
        </>
    )
}


const styles = {
    contentDiv: 'col-md-12 d-flex flex-row flex-wrap w-100',
    contentDetails: 'd-flex flex-row justify-content-between mt-3 w-100',
    contentButton: 'd-flex flex-row justify-content-between align-items-center w-100',
}

export default EditSettings