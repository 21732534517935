import React, { useState } from 'react';
import { FiChevronDown } from "react-icons/fi";
import SmallModal from '../modals/SmallModal';

function ItemVariableCost() {

  const [dropGeneral, setDropGeneral] = useState(false);
  const [dropVehicleCost, setDropVehicleCost] = useState(false);
  const [dropDriverCost, setDropDriverCost] = useState(false);
  const [showModal, setShowModal] = useState(false);

  return (
    <div className="m-3">
      <div className="col-md-12 pt-2 mb-4">
        <h1 className="heading-h1 fs-2 mb-0 fw-bold">Variable cost</h1>
        <h6 className="subtitle-graph">Calculate the cost of the lane</h6>
      </div>
      <div className="col-md-12 mt-4">
        <button className='btn-secondaryOff w-100'>USE STANDARD VALUES</button>
      </div>
      <div className="col-md-12 mt-3">
        <div className="btnDropOff w-100">
          <div onClick={() => setDropGeneral(!dropGeneral)}>
            <FiChevronDown className={`icon-details ${dropGeneral ? "icon-details-rotate" : ''}`} />
            <span className='letterDropOff fw-normal'>General</span>
          </div>
          <div className="me-1">
            <input
              type="checkbox"
              value="1"
              id="flexCheckDefault"
              className="form-check-input"
            />
          </div>
        </div>
        {dropGeneral &&
          <div className="col-md-12 mt-2">
            <div>
              <span className='text-name-user'>Avg. year mileage</span>
            </div>
            <div className="mt-2">
              <input type="text" className='form-control form-modal' placeholder='$100.000' />
            </div>
          </div>}
      </div>
      <div className="col-md-12 mt-3">
        <div className="btnDropOff w-100">
          <div onClick={() => setDropVehicleCost(!dropVehicleCost)}>
            <FiChevronDown className={`icon-details ${dropVehicleCost ? "icon-details-rotate" : ''}`} />
            <span className='letterDropOff fw-normal'>Vehicle based cost</span>
          </div>
          <div className="me-1">
            <input
              type="checkbox"
              value="1"
              id="flexCheckDefault"
              className="form-check-input"
            />
          </div>
        </div>
        {dropVehicleCost &&
          <div className='w-100'>
            <div className="col-md-12 d-flex flex-column mt-3 w-100">
              <label className='text-name-user mb-2'>Trailer type</label>
              <select className="selectOffMenu w-100 border-end-0">
                <option>Dry van</option>
                <option>Reefer</option>
                <option>Flaatbed</option>
              </select>
            </div>
            <div className='d-flex flex-row justify-content-between mt-2 gap-2'>
              <div className='col-md-6'>
                <label className='text-name-user mb-2'>Fuel per year</label>
                <input type="text" className='form-control form-modal' placeholder='$100' />
              </div>
              <div className='col-md-6'>
                <label className='text-name-user mb-2'>Maintenance</label>
                <input type="text" className='form-control form-modal' placeholder='$100' />
              </div>
            </div>
            <div className='d-flex flex-row justify-content-between mt-2 gap-2'>
              <div className='col-md-6'>
                <label className='text-name-user mb-2'>Equipment</label>
                <input type="text" className='form-control form-modal' placeholder='$100' />
              </div>
              <div className='col-md-6'>
                <label className='text-name-user mb-2'>Tires</label>
                <input type="text" className='form-control form-modal' placeholder='$100' />
              </div>
            </div>
            <div className='d-flex flex-row justify-content-between mt-2 gap-2'>
              <div className='col-md-6'>
                <label className='text-name-user mb-2'>Permits</label>
                <input type="text" className='form-control form-modal' placeholder='$100' />
              </div>
              <div className='col-md-6'>
                <label className='text-name-user mb-2'>Insurance</label>
                <input type="text" className='form-control form-modal' placeholder='$100' />
              </div>
            </div>
          </div>}
      </div>
      <div className="col-md-12 mt-3">
        <div className="btnDropOff w-100">
          <div onClick={() => setDropDriverCost(!dropDriverCost)}>
            <FiChevronDown className={`icon-details ${dropDriverCost ? "icon-details-rotate" : ''}`} />
            <span className='letterDropOff fw-normal'>Driver based cost</span>
          </div>
          <div className="me-1">
            <input
              type="checkbox"
              value="1"
              id="flexCheckDefault"
              className="form-check-input"
            />
          </div>
        </div>
        {dropDriverCost &&
          <div className='w-100'>
            <div className='d-flex flex-row justify-content-between mt-2 gap-2'>
              <div className='col-md-6'>
                <label className='text-name-user mb-2'>Anual wages</label>
                <input type="text" className='form-control form-modal' placeholder='$100' />
              </div>
              <div className='col-md-6'>
                <label className='text-name-user mb-2'>Anual benefits</label>
                <input type="text" className='form-control form-modal' placeholder='$100' />
              </div>
            </div>
          </div>}
      </div>
      <div className="divider-4"></div>
      <div className="col-md-12 cardOffMenu d-flex justify-content-center">
        <span className='title-calculator'>$ 1.81</span>
      </div>
      <div className="col-md-12 mt-4">
        <button type="button" className="btn btn-outline-success btn-route-lg w-100" onClick={() => setShowModal(!showModal)}>CREATE NEW SETTINGS</button>
      </div>
      <SmallModal showSmallModal={showModal} setShowSmallModal={setShowModal} title={'New variable cost'} letterBtn={'CREATE NEW SETTING'} subTitle={'Setting name'} buttons={false} titleAlert={'Created setting'} textAlert={'You have a new variable cost settings'} />
    </div >
  )
}



export default ItemVariableCost