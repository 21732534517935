import React from 'react';
import { FiLogIn } from "react-icons/fi";
import LastRoutes from './LastRoutes';

const StatisticsCard = () => {
    return (  <div className="card card-graph-bg">
    <div className="card-body">
        <div className="d-flex flex-row flex-wrap mb-2">
            <div className="col-10">
                <h5 className="card-title title-graph fw-bold">Last routes</h5>
                <h6 className="card-subtitle mb-2 subtitle-graph">Last routes created</h6>
            </div>
            <div className="col-2 text-end">
                <FiLogIn className="tool-icon" />
            </div>
           <LastRoutes />
           <LastRoutes />
           <LastRoutes />

        </div>
    </div>
</div> );
}
 
export default StatisticsCard;