import React, { useEffect, useState } from 'react';
import { FiHelpCircle } from "react-icons/fi";
import GraphCard from "../../components/GraphCard";
import IndicatorsBar from './IndicatorsBar';
import Layout from '../../components/layout/Layout';
import SidebarIndicators from './SidebarIndicators';
import { ProtectedRoute } from '../../app/ProtectedRoute';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { deleteSelectedIndicators, selectExternalAction, setFromExternalAction, setSelectedIndicators } from '../../redux/indicators/IndicatorsSlice';
import { useQuery } from '../../hooks/useQuery';

const Indicators = ({ routes }) => {
  let history = useHistory();

  const dispatch = useDispatch();
  const extAction = useSelector(selectExternalAction);

  const [showBtnCompare, setShowBtnCompare] = useState(false);
  const [showBtnMerge, setShowBtnMerge] = useState(false);
  const [showChecks, setShowChecks] = useState(false);
  const [checkList, setCheckList] = useState([]);
  let action = useQuery();
  let queryEl = action.get('action');
  const itemGraph = [
    { id: 1, data: [], indicator_type_by_comp: 1, },
    { id: 2, data: [], indicator_type_by_comp: 1, },
    { id: 3, data: [], indicator_type_by_comp: 1, },
    { id: 4, data: [], indicator_type_by_comp: 1, },
    { id: 5, data: [], indicator_type_by_comp: 1, },
    { id: 6, data: [], indicator_type_by_comp: 1, },
    { id: 7, data: [], indicator_type_by_comp: 1, },
    { id: 8, data: [], indicator_type_by_comp: 1, },
    { id: 9, data: [], indicator_type_by_comp: 1, },
  ];

  const buttonCompare = () => {
    dispatch(setFromExternalAction(0));
    setShowBtnCompare(!showBtnCompare);
    setShowBtnMerge(false);
  }

  const buttonMerge = () => {
    dispatch(setFromExternalAction(0));
    setShowBtnMerge(!showBtnMerge);
    setShowBtnCompare(false);

  }

  useEffect(() => {
    if (showBtnMerge || showBtnCompare) {
      setShowChecks(true);
    }

    if (!showBtnMerge && !showBtnCompare) {
      setShowChecks(false);
      if (extAction === 1) {
        setShowChecks(true);
        setShowBtnMerge(true);
      } else if(extAction === 2) {
        setShowChecks(true);
        setShowBtnCompare(true);
      } else{
        setShowChecks(false);
        setShowBtnMerge(false);
        setShowBtnCompare(false);

      }
    }

  }, [showBtnMerge, showBtnCompare, extAction]);
  
  console.log('Compare', showBtnCompare);
  console.log('Merge', showBtnMerge);


  const getDetails = (idIndicator) => history.push("/indicators/" + idIndicator);

  const handleChange = (e, item) => {

    if (checkList.length >= 6) {
      let arrTemp = checkList;
      arrTemp.shift();
      setCheckList(arrTemp);
    }
    if (e.target.checked === true) {
      setCheckList([...checkList, Number(e.target.value)]);
      dispatch(setSelectedIndicators(item));
    } else {
      const selectedAcc = checkList.filter((a) => {
        if (a === Number(e.target.value)) return false;
        return true;
      });
      setCheckList([...selectedAcc]);
      dispatch(deleteSelectedIndicators(item));
    }
  }

 
  return (
    <Layout >
      <ProtectedRoute path={routes?.path}
        routes={routes?.Routes}
        exact={true}>
        <div className="d-flex flex-column flex-wrap flex-md-nowrap pt-3 pb-2 px-md-4">
          <h1 className="heading-h1 fw-bold">View indicators <FiHelpCircle className="icon-h1 ms-2" /></h1>
          <p className="subheading-top">Here, you can view, analyze and compare data</p>
          <IndicatorsBar />
          <div className="d-flex flex-row flex-wrap">
            <div className="col-md-11 mt-2 mb-5 d-grid gap-3">
              <div className="col-md-12 grid-layout">
                {itemGraph.map((item) =>
                  <GraphCard
                    showChecks={showChecks}
                    isChecked={checkList.includes(item.id)}
                    item={item}
                    btnIcon={true}
                    widthCard={'auto'}
                    classItem={'box'}
                    classItemElement={'box-active'}
                    key={item.id} goTo={getDetails} handleChange={handleChange} checkList={checkList} />
                )}
              </div>
            </div>
            <div className="col-md-1 mt-2 mb-1 d-flex flex-row justify-content-end">
              <SidebarIndicators btnCompare={showBtnCompare} btnMerge={showBtnMerge} buttonCompare={buttonCompare} buttonMerge={buttonMerge} arrToCompare={checkList} />
            </div>
          </div>
        </div>
      </ProtectedRoute>
      {routes.Routes.map((item, index) =>
        <ProtectedRoute
          path={item.path}
          routes={item}
          component={item.Component}
          key={index}
          exact={item.isExact} />
      )}
    </Layout>
  )
}

export default Indicators