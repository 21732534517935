let API_URL = process.env.REACT_APP_API_AUTH_DEV;

const requestDeleteUserFolder = async (id, user_token) => {
  let response;

  await fetch(`${API_URL}user-route-folder/${id}`, {
    method: "DELETE",
    // mode: 'no-cors',
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: user_token,

      // "Accept-Encoding": "gzip, deflate, br"
    },
  })
    .then((response) => response.json())
    .then((data) => {
      response = data;
    })
    .catch((error) => {
      response = {
        state: 500,
        data: null,
        error,
        message: "Connection error, try again later",
      };
    });
  return response;
};

export default requestDeleteUserFolder;
