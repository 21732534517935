import React, { useEffect, useRef } from 'react';
import { MapContainer as LeafletMap, TileLayer, ZoomControl, GeoJSON } from "react-leaflet";
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import statesData from '../statesData';

const GeneralMap = (props) => {

    const ACCESS_TOKEN = process.env.REACT_APP_API_KEY_MAP;
    const URL = `https://maps.geoapify.com/v1/tile/osm-bright/{z}/{x}/{y}.png?&apiKey=${ACCESS_TOKEN}`;
    const TOWER_LOCATION = props?.tower ?? [37.7752701, -122.4312043];

    /* A React hook that is used to store a reference to the GeoJSON component. */
    const geoJsonLayer = useRef(null);

    /* A React hook that is used to store a reference to the GeoJSON component. */
    useEffect(() => {
        if (geoJsonLayer.current) {
            geoJsonLayer.current.clearLayers().addData(statesData);
        }
    }, [statesData]);

    const getColor = (d) => {
        return d > 1000 ? '#209E59' :
               d > 500  ? '#209E59' :
               d > 200  ? '#209E59' :
               d > 100  ? '#209E59' :
               d > 50   ? '#209E59' :
               d > 20   ? '#244436' :
               d > 10   ? '#266846' :
                          '#244436';
    } 

   const onEachFeature = (feature, layer) => {
        layer.options.color = "#2F444D"
        layer.options.fillOpacity = 1
        layer.options.weight = 2
        layer.options.dashArray = 1
        layer.options.opacity = 1
      
        layer.bindPopup(feature.properties.NAMOBJ)
        layer.on({
          mouseover: onMouseOver,
          mouseout: onMouseOut,
        })
      }

    const onMouseOver = (event) => {
        event.target.bringToFront()
        event.target.setStyle({
          color: "white",
          weight: 1
        });
      }

     const onMouseOut = (event) => {
        event.target.bringToBack()
        event.target.setStyle({
          color: "#2F444D",
          weight: 1
        });
      }
    const style = (feature) => {
        return {
            fillColor: getColor(feature.properties.density),
            weight: 1,
            opacity: 1,
            color: '#2F444D',
            // dashArray: '1',
            fillOpacity: 1
        };
    }   

    return (
        <LeafletMap
            center={TOWER_LOCATION}
            zoom={props?.zoom ?? 14}
            className="map p-3"
            style={props.inLineStyles}
            zoomControl={false}>
            <TileLayer url={URL} />
            {
                props.showControls && <ZoomControl position={'bottomright'} />
            }

            {
                props.showStateData && <GeoJSON ef={geoJsonLayer} data={statesData} style={style} onEachFeature={onEachFeature} />
            }
        </LeafletMap>);
}

/* Setting default props for the component. */
GeneralMap.defaultProps = {
    tower: [37.7752701, -122.4312043],
    inLineStyles: {},
    showControls: false,
    zoomBase: 14,
    showStateData: false,
};

export default GeneralMap;