import React, { useState } from "react";
import { useController, useForm } from "react-hook-form";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { signedIn } from "../../redux/auth/GuardianSlice";
import Swal from "sweetalert2";
import logo from "../../app/images/90466.svg";
import logo_google from "../../app/images/XMLID_28_.png";
import requestSignIn from "../../services/auth/requestSignIn";
import { useGoogleLogin } from '@react-oauth/google';
import { FiEye } from "react-icons/fi";
import requestInfoUser from "../../services/user/requestInfoUser";
import { setUserData } from "../../redux/user/UserSlice";
import requestResendConfirmationCode from "../../services/auth/requestResendConfirmationCode";
import { useCookies } from "../../hooks/useCookies";


export const Input = (props) => {
  const { field } = useController(props);

  return (
    <div>
      <input {...field} placeholder={props.name} className={props.className}/>
    </div>
  );
}

const Login = () => {
  let history = useHistory();
  let location = useLocation();
  const dispatch = useDispatch();

  const [email, setEmail] = useState();
  const [showPass, setShowPass] = useState(false);
  const [verifyAccount, setVerifyAccount] = useState(false);

/* A custom hook that I created to set a cookie with the name tokenCounter and the value counter. With 3 hours for expire */
  const {createCookie} = useCookies('tokenCounter', 'counter', 1/8);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const loginWGoogle = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const userInfo = await fetch(
        'https://www.googleapis.com/oauth2/v3/userinfo',
        { headers: { Authorization: `Bearer ${tokenResponse.access_token}` } },
      )
        .then((response) => response.ok ? response.json() : { error: true })
        .catch((error) => ({ error: true }));
      if (!userInfo.error) {
        logIn({ user_google_token: userInfo.sub });
      }
    },
    onError: errorResponse => console.log('the developer must check src\pages\Auth\Login.jsx : Line 37'),
  });

  function disp(user_token) {
    dispatch(signedIn(user_token));
    createCookie();
  }

  const logIn = async (formData) => {
    let { from } = location.state || { from: { pathname: "/dashboard" } };
    const Toast = Swal.mixin({
      toast: true,
      position: "top",
      showConfirmButton: false,
      timer: 9000,
      heightAuto: true,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
    try {
      const responseData = await requestSignIn(formData);
      if(responseData.error === 'Unconfirmed') {
        return setVerifyAccount(true);
      }
      switch (responseData.state) {
        case 200:
          // window.localStorage.setItem("user_token", ("Bearer " + responseData.data));
          disp(("Bearer " + responseData.data));
          let response = await requestInfoUser(("Bearer " + responseData.data));
          if (response.state === 200) {
            dispatch(setUserData(response.data));
          }
          history.replace(from);
          break;
          case 401:
            Toast.fire({
              icon: "warning",
              title: responseData.message,
            });
          break;
        default:
          Toast.fire({
            icon: "warning",
            title: responseData.message,
          });
          break;
      }
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * It takes a form event, prevents the default action, then sends the form data to the server as
   * JSON.
   */
  const handleSubmitter = async (data) => {
    await logIn(data);
  };

  const resendConfirmationEmailHandler = async () => {
    const response = await requestResendConfirmationCode(email);
    const Toast = Swal.mixin({
        toast: true,
            position: "top",
            showConfirmButton: false,
            timer: 9000,
            heightAuto: true,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
        });
        Toast.fire({
            icon: "warning",
            title: response.message,
        });
    if (response.state == 200) {
      setVerifyAccount(false);
    }
}

  return (
    <div className="content-login singUp">
      <div className="brand-login d-flex flex-column flex-wrap justify-content-center col-lg-5 gap-2 labels-group">
            <img src={logo} alt="" className="logo-login" onClick={() => setVerifyAccount(false)}/>
        <span className="dialog-login">More than a loadboard</span>
      </div>
      {!verifyAccount &&
      <div className="form-sign-in col-xl-7 col-md-8">
        <h1 className="h3 mb-3 text-welcome text-center">¡Welcome back!</h1>
        <button className="btn btn-light btn-block w-100 mb-3 btn-google" onClick={loginWGoogle}>
          <img src={logo_google} alt="" className="me-1"/> Login with Google
        </button>
        <p className="orLogin">Or login with e-mail</p>
        <form className="d-flex flex-row flex-wrap form-singUp" onSubmit={handleSubmit(handleSubmitter)}>
          <div className="mb-1 col-md-12">
            <label className="form-label mb-1">Email address</label>
            <Input  {...register("user", {
                required: true,
                pattern:
                  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
              })}
              control={control}
              type="email"
              className={errors.user ? "form-control invalid" : "form-control"}
              placeholder="Email address"
              onChange={(e) => setEmail(e.target.value)}/> 
            {/* <input
             
            /> */}
          </div>

          <div className="mb-2 has-show-pass col-md-12">
            <label className="form-label mb-1">Password</label>
            <div className="input-group mb-3">
              <input
                {...register("password", { required: true, minLength: 6 })}
                type={showPass ? "text" :"password"}
                className={
                  errors.password ? "form-control border-end-0 invalid" : "form-control border-end-0"
                }
                placeholder="Password"
              />
              <span className="input-group-text btn-eye" onClick={()=> setShowPass(!showPass)}>
                <FiEye />
              </span>
            </div>
          </div>
          <div className="d-flex flex-row flex-wrap justify-content-between col-12">
            <div className="form-check">
              <input
                className="form-check-input me-2"
                type="checkbox"
              />
              <label className="form-check-label">Keep me logged in</label>
            </div>
            <div className="mb-3">
              <Link className="forgot-link" to={"/recovery-password"}>
                Forgot your password?
              </Link>
            </div>
          </div>
          <button type="submit" className="w-100 btn btn-lg btn-success mb-3">
            LOG IN
          </button>
        </form>
        {errors.user?.type === "required" ? (
          <p className="text-danger text-error" role="alert">
            The email/username field is required
          </p>
        ) : (
          ""
        )}
        {errors.password?.type === "required" ? (
          <p className="text-danger text-error" role="alert">
            The password field is required
          </p>
        ) : (
          ""
        )}
        <p className="orLogin">Don’t have an account?</p>
        <button onClick={() => history.replace("/sign-up")} className="w-100 btn btn-lg btn-secondary mb-3">
          SIGN UP
        </button>
      </div>}

      {verifyAccount &&
      <div className="form-sign-in col-xl-7 col-md-8">
        <h1 className="h3 mb-3 text-welcome text-center">Verify your account</h1>
        <p className="orLogin">Confirmation e-mail</p>
        <p className="form-label mb-3 text-center">Which method would you like <span className="text-success">to use to verify your account?</span> </p>
        <button onClick={resendConfirmationEmailHandler} className="w-100 btn btn-lg btn-secondary mb-3">
          SEND MAIL CONFIRMATION
        </button>
      </div>
      }
    </div>
  );
};

export default Login;
