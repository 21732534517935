import React from 'react';

const Notifications = () => {
    return (
        <div className='mt-3 mb-5'>
            <div className='mt-3 mb-5 flex-system-content'>
                <div className="d-flex flex-row">
                    <div className="initial-element">Notices</div>
                    <div className="d-flex flex-column">
                        <div className="p-2 middle-element d-flex flex-row">
                            <div className="form-check form-switch me-2">
                                <input className="inputSettings form-check-input" type="checkbox" role="switch" />
                            </div>
                            <div>
                                <p className='fs-6 middle-element middle-element-text fw-bold mb-0 p-0'>Truck news</p>
                                <p className='fs-6 middle-element middle-element-text mb-0 p-0'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                            </div>
                        </div>
                        <div className="p-2 middle-element d-flex flex-row">
                            <div className="form-check form-switch me-2">
                                <input className="inputSettings form-check-input" type="checkbox" role="switch" />
                            </div>
                            <div>
                                <p className='fs-6 middle-element middle-element-text fw-bold mb-0 p-0'>Ef routing report</p>
                                <p className='fs-6 middle-element middle-element-text mb-0 p-0'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-row">
                    <div className="initial-element">Notices</div>
                    <div className="d-flex flex-column">
                        <div className="p-2 middle-element d-flex flex-row">
                            <div className="form-check form-switch me-2">
                                <input className="inputSettings form-check-input" type="checkbox" role="switch" defaultChecked />
                            </div>
                            <div className='col-md-12 d-flex flex-row gap-3 mt-1'>
                                <div className='col-md-3'>
                                    <p className='fs-6 middle-element middle-element-text fw-bold mb-1 p-0'>Sales volume</p>
                                    <select className="selectSettings w-100">
                                        <option>Daily report</option>
                                        <option>Data 1</option>
                                        <option>Data 1</option>
                                        <option>Data 1</option>
                                    </select>
                                </div>
                                <div className='col-md-3'>
                                    <p className='fs-6 middle-element middle-element-text mb-1 p-0'>Category</p>
                                    <select className="selectSettings w-100">
                                        <option>Volume sales in LA</option>
                                        <option>Data 1</option>
                                        <option>Data 1</option>
                                        <option>Data 1</option>
                                    </select>
                                </div>
                                <div className='col-md-3'>
                                    <p className='fs-6 middle-element middle-element-text mb-1 p-0'>Conditional</p>
                                    <select className="selectSettings w-100">
                                        <option>Get over</option>
                                        <option>Data 1</option>
                                        <option>Data 1</option>
                                        <option>Data 1</option>
                                    </select>
                                </div>
                                <div className='col-md-3'>
                                    <p className='fs-6 middle-element middle-element-text mb-1 p-0'>Value</p>
                                    <input type="text" className='form-control formSettings' placeholder='$100' />
                                </div>
                            </div>
                        </div>
                        <div className="p-2 middle-element d-flex flex-row">
                            <div className="form-check form-switch me-2">
                                <input className="inputSettings form-check-input" type="checkbox" role="switch" defaultChecked />
                            </div>
                            <div className='col-md-12 d-flex flex-row gap-3 mt-1'>
                                <div className='col-md-3'>
                                    <p className='fs-6 middle-element middle-element-text fw-bold mb-1 p-0'>Sales volume</p>
                                    <select className="selectSettings w-100">
                                        <option>Daily report</option>
                                        <option>Data 1</option>
                                        <option>Data 1</option>
                                        <option>Data 1</option>
                                    </select>
                                </div>
                                <div className='col-md-3'>
                                    <p className='fs-6 middle-element middle-element-text mb-1 p-0'>Category</p>
                                    <select className="selectSettings w-100">
                                        <option>Volume sales in LA</option>
                                        <option>Data 1</option>
                                        <option>Data 1</option>
                                        <option>Data 1</option>
                                    </select>
                                </div>
                                <div className='col-md-3'>
                                    <p className='fs-6 middle-element middle-element-text mb-1 p-0'>Conditional</p>
                                    <select className="selectSettings w-100">
                                        <option>Get over</option>
                                        <option>Data 1</option>
                                        <option>Data 1</option>
                                        <option>Data 1</option>
                                    </select>
                                </div>
                                <div className='col-md-3'>
                                    <p className='fs-6 middle-element middle-element-text mb-1 p-0'>Value</p>
                                    <input type="text" className='form-control formSettings' placeholder='$100' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='d-flex flex-row flex-wrap justify-content-end gap-3 contentButtonSettings'>
                <button type='button' className='btn btn-lg btn-secondary'>RESET ALL CHANGES</button>
                <button type='button' className='btn btn-lg btn-success'>SAVE CHANGES</button>
            </div>
        </div>
    )
}

export default Notifications