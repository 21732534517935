import React from 'react'
import { FiArrowUpRight, FiArrowDownRight } from "react-icons/fi";

const LastRoutes = () => {
    return (
        <div className='col-12'>
            <div className="divider-2"></div>
            <p className="subtitle-graph mb-0">05/08/22</p>
            <p className="route-title">Default route</p>
            <div className="d-flex flex-row flex-wrap justify-content-between mb-2">
                <div className='d-flex flex-row flex-wrap align-items-center'>
                    <div className="icon-route me-2">
                        <FiArrowUpRight />
                    </div>
                    <div>
                        <p className='route-name fw-bold mb-0'>Athens, GRC</p>
                        <p className='route-desc mb-0'>Piraeus Harbor</p>
                    </div>
                </div>
                <div>
                    <p className='route-status'>Full load</p>
                </div>
            </div>
            <div className="d-flex flex-row flex-wrap justify-content-between">
                <div className='d-flex flex-row flex-wrap align-items-center'>
                    <div className="icon-route me-2">
                        <FiArrowDownRight />
                    </div>
                    <div>
                        <p className='route-name fw-bold mb-0'>Athens, GRC</p>
                        <p className='route-desc mb-0'>Piraeus Harbor</p>
                    </div>
                </div>
                <div>
                    <p className='route-status'>Full load</p>
                </div>
            </div>
        </div>
    );
}

export default LastRoutes;