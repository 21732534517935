import React from 'react'
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { FiHelpCircle, FiMoreHorizontal } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ChartComponent from '../../components/ChartComponent';
import GeneralMap from '../../components/GeneralMap';
import SimpleTable from '../../components/SimpleTable';
import { useQuery } from '../../hooks/useQuery';
import { selectExternalAction, setFromExternalAction } from '../../redux/indicators/IndicatorsSlice';
import statesData from '../../statesData';
import IndicatorsDetailBar from './IndicatorsDetailBar';
const IndicatorsDetail = ({ item }) => {
    let sectionName = useQuery();
    let history = useHistory();
    let sect = sectionName.get('section_name');

    const externalActionSelector = useSelector(selectExternalAction);

    const dispatch = useDispatch();

    const customAction = (option) => {
        dispatch(setFromExternalAction(option));
        history.push('/indicators');
    }
    return (
        <>
            <div className="d-flex flex-column flex-wrap flex-md-nowrap pt-3 pb-2 px-md-4">
                <IndicatorsDetailBar showSource />
                <div className="card my-3">
                    <div className="card-body map-body p-4">
                        {
                            !sect ?
                                <GeneralMap
                                    inLineStyles={styles}
                                    showControls
                                    zoom={4}
                                    tower={[39.381266, -97.922211]}
                                    showStateData
                                    polygonsPoint={statesData} />
                                :
                                <>
                                    <div className="d-flex flex-row flex-wrap justify-content-between align-items-center pt-3 px-2">
                                        <div>
                                            <h4 className='mixing-indicator-title mb-0'>Indicator name <FiHelpCircle className="icon-h2 ms-1" /></h4>
                                            <p className='mixing-indicator-subtitle mb-0'>(Dollars per kilowatt)</p>
                                        </div>
                                    </div>
                                    <ChartComponent height={'350px'} />
                                </>
                        }
                    </div>
                </div>
                <div className="card">
                    <div className="card-body px-4 pt-4">
                        <SimpleTable />
                    </div>
                </div>
                <div className={!sect ? "col-12 my-3 d-flex flex-row flex-wrap flex-gaping" : "col-12 my-3 d-flex flex-row flex-wrap flex-gaping justify-content-end"}>
                    {!sect &&
                        <>
                            <div className="col-md-4">
                                <button type="button" className="btn btn-outline-success btn-green-outline" onClick={() => customAction(1)}>MERGE PLOT</button>
                            </div>
                            <div className="col-md-4">
                                <button type="button" className="btn btn-outline-success btn-green-outline" onClick={() => customAction(2)}>COMPARE INDICATORS</button>
                            </div>
                        </>
                    }
                    <div className="col-md-4">
                        <button type="button" className="btn btn-outline-success btn-green-outline">GENERATE REPORT</button>
                    </div>
                </div>
            </div>
        </>
    );
}
const styles = {
    height: '420px'
}
export default IndicatorsDetail;