import React from 'react';
import Modal from 'react-bootstrap/Modal';
import ChartComponent from '../ChartComponent';
import { FiX, FiHelpCircle } from "react-icons/fi";

const DetailsGraphIndicators = ({ showModal, setShowModal }) => {
    const handleClose = () => setShowModal(false);
    const info = [{
        name: "Nada por aquí", //will be displayed on the y-axis
        data: [1000, 750, 150, 160, 900, 270, 880, 400, 410, 180]
    }, {
        name: "Y aquí menos", //will be displayed on the y-axis
        data: [100, 920, 975, 480, 820, 180, 800, 780, 600, 230]
    }];

    const chartOptions = {
        chart: {
            id: "simple-bar",
            toolbar: {
                show: false
            },
        },
        grid: {
            show: true,
            borderColor: '#7E7E7E',
            strokeDashArray: 0,
            position: 'back',
            clipMarkers: true,
            xaxis: {
                lines: {
                    show: true
                }
            },
            yaxis: {
                lines: {
                    show: true
                }
            },
            padding: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0
            }
        },
        dataLabels: {
            enabled: false,
        },
        legend: {
            show: false,
        },
        stroke: {
            curve: "straight",
            width: 1
        },
        title: {
            text: 'Killowat price',
            align: 'left',
            offsetY: 10,
            style: {
                fontSize: '14px',
                fontWeight: 400,
                fontFamily: 'SF Pro Display',
                color: '#CACACA',
            },
        },
        subtitle: {
            text: '(Dollars per killowat)',
            align: 'left',
            style: {
                fontSize: '14px',
                fontWeight: 400,
                fontFamily: 'SF Pro Display',
                color: '#646D73'
            },
        },
        markers: {
            size: 3,
            colors: undefined,
            strokeColor: '#fff',
            strokeWidth: 1,
            strokeOpacity: 0.9,
            fillOpacity: 1,
            shape: "circle",
            radius: 2,
            offsetX: 0,
            offsetY: 0,
            hover: {
                size: 4
            }
        },
        xaxis: {
            categories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
        }
    };
    return (
        <Modal show={showModal} onHide={handleClose}
            centered
            dialogClassName="modal-90w"
            className='modalDetailsGraph'>
            <Modal.Header>
                <Modal.Title>
                    <span className='w-100'><FiHelpCircle className="icon-h1 me-2 mb-1" />Indicator name</span>
                </Modal.Title>
                {/* <FiX className='btn-closeModal' onClick={handleClose} /> */}
            </Modal.Header>
            <div className='d-flex flex-row flex-wrap w-100'>
                <div className="col-md-12">
                    <span className='text-contentTooltip'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum tempus nunc ligula, sit amet elementum massa consectetur non. Proin luctus, felis ut facilisis scelerisque, ipsum enim imperdiet magna, at cursus nibh quam egestas mi. Proin fermentum ipsum pellentesque sodales vulputate. Maecenas vel bibendum enim. Nulla auctor tortor a dolor auctor, ut condimentum est congue. </span>
                </div>
                <div className="col-md-12">
                    <ChartComponent data={info} chartOptions={chartOptions} type={'line'} height={"307px"} />
                </div>
            </div>
        </Modal >
    )
}

export default DetailsGraphIndicators