import React, { useState } from 'react';
import { FiColumns } from "react-icons/fi";
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import mergePlot from "../../app/images/mergeplot.svg";

const SidebarIndicators = (props) => {
    let history = useHistory();

    const Toast = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 4000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    const goToCompare = (e) => {
        if (props?.arrToCompare.length <= 1) {
            return Toast.fire({
                icon: 'error',
                title: 'Select more elements'
            });
        }
        if (e?.target?.textContent === 'COMPARE INDICATORS') {
            history.push(`/indicators/compare/mix?section_name=${e?.target?.textContent ?? 'section'}`);
        } else {
            history.push(`/indicators/merge/mix?section_name=${e?.target?.textContent ?? 'section'}`);
        }
    }

    return (
        <>
            <div className="card" style={{ height: '100%', width: '60px' }}>
                <div className='d-flex flex-row justify-content-center contentIcon'>
                    <FiColumns className='icon-route-planning' onClick={() => props.buttonCompare()} />
                </div>
                <div className='d-flex flex-row justify-content-center contentIcon'>
                    <img src={mergePlot} alt="" className='icon-route-planning' onClick={() => props.buttonMerge()} />
                </div>
                <div className='d-flex flex-row justify-content-center contentIcon'>
                    <FiColumns className='icon-route-planning' />
                </div>
            </div>
            {props.btnCompare && <button className='btn btn-success btnCompare' onClick={(e) => goToCompare(e, 'compare')}>COMPARE INDICATORS</button>}
            {props.btnMerge && <button className='btn btn-success btnCompare' onClick={(e) => goToCompare(e, 'merge')}>MERGE PLOTS</button>}
        </>
    )
}

export default SidebarIndicators