import React from 'react';
import { ProtectedRoute } from '../../app/ProtectedRoute';
//components
import { FiHelpCircle } from "react-icons/fi";
import Layout from '../../components/layout/Layout';
import FilterRouteBar from './FilterRouteBar';


const RoutePlanning = ({ routes }) => {
    return (<Layout>
        <div className="d-flex flex-column flex-wrap flex-md-nowrap pt-3 pb-2 px-md-4">
            <h1 className="heading-h1 fw-bold">Plan your route <FiHelpCircle className="icon-h1 ms-2" /></h1>
            <p className="subheading-top">Here, you can create your routes and find trips</p>
            <FilterRouteBar />
            <div className="divider-3"></div>
            {routes.Routes.map((item, index) =>
                <ProtectedRoute
                    path={item.path}
                    routes={item}
                    component={item.Component}
                    key={index}
                    exact={item.isExact} />
            )}
        </div>
    </Layout>);
}

export default RoutePlanning;