import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	routes: [],
	default_details: {},
};

export const routeSlice = createSlice({
	name: "route",
	initialState,
	reducers: {
		setDefaultDetails: (state, action) => {
			let data = action.payload;
			state.default_details = data;
		},
	},
});

export const { setDefaultDetails } = routeSlice.actions;
export default routeSlice.reducer;

