import Login from "./views/Auth/Login";
import SignUp from "./views/Auth/SignUp";
import RecoveryPassword from "./views/Auth/RecoveryPassword";
import Dashboard from "./views/Dashboard";
import MyRoutes from "./views/MyRoutes";
import RoutePlanning from "./views/RoutePlanning";
import CalculatedRoute from "./views/RoutePlanning/CalculatedRoute";
import RecommendedRoutes from "./views/RoutePlanning/RecommendedRoutes";
import RouteResults from "./views/RoutePlanning/RouteResults";
import Indicators from "./views/Indicators";
import IndicatorsDetail from "./views/Indicators/IndicatorsDetail";
import Inbox from "./views/Inbox";
import Settings from "./views/Settings";
import GeneralTabs from "./components/GeneralTabs";
import EditInfoUser from "./views/Settings/Tabs/EditInfoUser";
import Help from "./views/Help";
import svgEf from './app/images/result.svg';
import { FiBarChart, FiCopy, FiMap, FiGitCommit, FiInbox, } from "react-icons/fi";
import MixingTemplate from "./views/Indicators/mixer/MixingTemplate";

const Routes = [
  {
    path: "/login",
    name: "Login",
    Component: Login,
    guard: false,
    isExact: true,
    icon: null,
    Routes: [],
  },
  {
    path: "/sign-up",
    name: "Sign up",
    Component: SignUp,
    guard: false,
    isExact: true,
    icon: null,
    Routes: [],
  },
  {
    path: "/recovery-password",
    name: "Recovery password",
    Component: RecoveryPassword,
    guard: false,
    isExact: true,
    icon: null,
    Routes: [],
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    Component: Dashboard,
    guard: true,
    isExact: true,
    icon: <FiCopy className="align-text-bottom icon-sidebar me-3" />,
    Routes: [],
  },
  {
    path: "/my-routes",
    name: "My Routes",
    Component: MyRoutes,
    guard: true,
    isExact: true,
    icon: <FiGitCommit className="align-text-bottom icon-sidebar me-3" />,
    Routes: [],
  },
  {
    path: null,
    name: "efData",
    Component: null,
    guard: true,
    isExact: false,
    icon: (
      <img
        src={svgEf}
        className="align-text-bottom icon-sidebar me-3"
        alt=""
        style={{ width: "19px" }}
      />
    ),
    Routes: [
      {
        path: "/indicators",
        name: "Indicators",
        Component: Indicators,
        guard: true,
        isExact: false,
        icon: <FiBarChart className="align-text-bottom icon-sidebar me-3" />,
        Routes: [
          {
            path: "/indicators/:idIndicator",
            name: "Indicators Detail",
            Component: IndicatorsDetail,
            guard: true,
            isExact: true,
            icon: null,
            Routes: [],
          },
          {
            path: "/indicators/compare/mix",
            name: "Indicators Compare",
            Component: MixingTemplate,
            guard: true,
            isExact: false,
            icon: null,
            Routes: [],
          },
          {
            path: "/indicators/merge/mix",
            name: "Indicators Merge",
            Component: IndicatorsDetail,
            guard: true,
            isExact: false,
            icon: null,
            Routes: [],
          },
        ],
      },
      {
        path: "/route-planning",
        name: "Route Planning",
        Component: RoutePlanning,
        guard: true,
        isExact: false,
        icon: <FiMap className="align-text-bottom icon-sidebar me-3" />,
        Routes: [
          {
            path: "/route-planning/search/results",
            name: "Route Planning Results",
            Component: RouteResults,
            guard: true,
            isExact: false,
            icon: null,
            Routes: [],
          },
          {
            path: "/route-planning/results/:by/:routeId",
            name: "Route detail",
            Component: CalculatedRoute,
            guard: true,
            isExact: true,
            icon: null,
            Routes: [],
          },
          {
            path: "/route-planning/:nameFolder",
            name: "Route detail",
            Component: RecommendedRoutes,
            guard: true,
            isExact: true,
            icon: null,
            Routes: [],
          },
          {
            path: "/route-planning",
            name: "Route Recommended",
            Component: RecommendedRoutes,
            guard: true,
            isExact: true,
            icon: null,
            Routes: [],
          },
        ],
      },
    ],
  },
  {
    path: "/inbox",
    name: "Inbox",
    Component: Inbox,
    guard: true,
    isExact: true,
    icon: <FiInbox className="align-text-bottom icon-sidebar me-3" />,
    Routes: [],
  },
  {
    path: "/settings/:idSettings",
    name: "Settings",
    Component: Settings,
    guard: true,
    isExact: false,
    icon: null,
    Routes: [
      {
        path: "/settings/:idSettings/:idEdit",
        name: "Settings Info-tabs",
        Component: EditInfoUser,
        guard: true,
        isExact: true,
        icon: null,
        Routes: [],
      }
    ],
  },
  {
    path: "/help",
    name: "Help",
    Component: Help,
    guard: true,
    isExact: true,
    icon: null,
    Routes: [],
  },
];
export default Routes;
