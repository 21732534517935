import React from 'react';
import { FiHelpCircle } from "react-icons/fi";
import Layout from '../../components/layout/Layout';

const index = () => {
  return (
    <Layout >
    <div className="d-flex flex-column flex-wrap flex-md-nowrap pt-3 pb-2 px-md-4">
      <h1 className="heading-h1 fw-bold">Admin. your routes <FiHelpCircle className="icon-h1 ms-2" /></h1>
      <p className="subheading-top">Here, you can administrate your routes</p>
    </div>
  </Layout>
  )
}

export default index