import React, { useState } from 'react';
import Calendar from 'moedim';
import styled from 'styled-components';
import ChartModal from '../modals/ChartModal';
import { FiCalendar, FiAlertCircle } from "react-icons/fi";

const ItemFuelPrices = () => {
    const [dateValue, setDateValue] = useState(new Date());
    const [dateDropdown, setDateDropdown] = useState(false);
    const [open, setOpen] = useState(false);
    const [showChartModal, setShowChartModal] = useState(false);

    const StyledCalendar = styled(Calendar)`
        --moedim-primary: #1c8c4f;
        width: 260px;
        border: 1px solid #364752;
        margin-top: -0.5rem;
        color: #cacaca;
        border-radius: 16px;
        background: #121A1F;
        padding: 24px 24px 24px 15px;
        position: absolute;
        z-index: 99;
    `;

    const formatDate = () => {
        var date = dateValue.toLocaleDateString("en-US");
        return date;
    }

    const dataCard = [
        { title: 'Killowat price', subTitle: '(Dollars per killowat)', dataNumber: '$5.298', numberYesterday: '$6.298', numberLastWeek: '$4.941' },
        { title: 'Diesel fuel price', subTitle: '(Dollars per gallon)', dataNumber: '$4.298', numberYesterday: '$8.298', numberLastWeek: '$6.941' }
    ]

    return (
        <div className="m-3">
            <div className="col-md-12 pt-2 mb-4">
                <h1 className="heading-h1 fs-2 mb-0 fw-bold">Fuel prices</h1>
                <h6 className="subtitle-graph">Calculate the cost of the lane</h6>
            </div>
            <div className="col-md-12 d-flex flex-row mt-2">
                <div className='me-2'>
                    <span className='text-name-user fw-bold'>Region</span>
                </div>
                <div>
                    <span className="letter-subtitle">Select your region</span>
                </div>
            </div>
            <div className="col-md-12 mt-2">
                <select className="selectOffMenu w-100">
                    <option>California</option>
                    <option>California</option>
                    <option>California</option>
                    <option>California</option>
                    <option>California</option>
                    <option>California</option>
                </select>
            </div>
            <div className="col-md-12 d-flex flex-row mt-2">
                <div className='me-2'>
                    <span className='text-name-user fw-bold'>Date</span>
                </div>
                <div>
                    <span className="letter-subtitle">Select your date</span>
                </div>
            </div>
            <div className="col-md-12 d-flex flex-row mt-2 w-100">
                <button onClick={() => setDateDropdown(!dateDropdown)} type="button" className="dateDrop w-100">
                    <FiCalendar className='letterDeparture' />
                    <span className='letterDeparture'>{formatDate()}</span>
                </button>
            </div>
            {dateDropdown &&
                <div className="col-md-12">
                    <StyledCalendar className='calendar' value={dateValue} onChange={(d) => setDateValue(d)} />
                </div>}

            <div className="col-md-12 mt-3">
                <button type="button" className="btn btn-success btn-route-lg w-100" onClick={() => setOpen(!open)}>CALCULATE</button>
            </div>
            <div className="divider-4"></div>

            {open &&
                <div> 
                    {dataCard.map((i, index) => <div className="col-md-12 cardOffMenu mt-3 mb-3" key={index}>
                        <div>
                            <div className="col-md-12 mt-2">
                                <div className='d-flex flex-row justify-content-between mb-0'>
                                    <span className='route-name mb-0'>{i.title}</span>
                                    <FiAlertCircle className='icon-circle' />
                                </div>
                                    <p className="letter-subtitle mb-0">{i.subTitle}</p>
                                <div>
                                    <span className='text-numberSuccess'>{i.dataNumber}</span>
                                </div>
                            </div>
                            <div className='col-md-12 d-flex flex-row justify-content-between align-items-center'>
                                <div>
                                    <span className='letter-subtitle'>Yesterday</span>
                                </div>
                                <div>
                                    <span className='letter-numberDanger'>{i.numberYesterday}</span>
                                </div>
                            </div>
                            <div className='col-md-12 d-flex flex-row justify-content-between align-items-center'>
                                <div>
                                    <span className='letter-subtitle'>Last week</span>
                                </div>
                                <div>
                                    <span className='letter-numberSuccess'>{i.numberLastWeek}</span>
                                </div>
                            </div>
                        </div>
                    </div>)}

                    <div className="col-md-12 mt-4">
                        <button type="button" className="btn btn-outline-success btn-route-lg w-100" onClick={() => setShowChartModal(!showChartModal)}>VIEW CHARTS</button>
                    </div>
                    <ChartModal showChartModal={showChartModal} setShowChartModal={setShowChartModal} />
                </div>}
        </div>
    )
}

export default ItemFuelPrices