import React, { useState } from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { FiHelpCircle, FiMoreHorizontal } from 'react-icons/fi';
import ChartComponent from '../../../components/ChartComponent';
import SimpleTable from '../../../components/SimpleTable';
const MixingIndicator = ({update_action, item, type}) => {
    const [swapper, setSwapper] = useState(false);
    return (
        <div className="card">
            <div className="card-body">
                <div className="d-flex flex-row flex-wrap justify-content-between align-items-center pt-3 px-2">
                    <div>
                        <h4 className='mixing-indicator-title mb-0'>Indicator name <FiHelpCircle className="icon-h2 ms-1" /></h4>
                        <p className='mixing-indicator-subtitle mb-0'>(Dollars per kilowatt)</p>
                    </div>
                    <div className="dropSettings neutral-drop">
                        <DropdownButton
                            drop={'down'}
                            title={<FiMoreHorizontal className='icon-h1 align-self-start' />}
                        >
                            <Dropdown.Item id='1' onClick={() => setSwapper(!swapper)}>Chart</Dropdown.Item>
                            <Dropdown.Item id='0' onClick={() => setSwapper(!swapper)}>Board</Dropdown.Item>
                        </DropdownButton>

                    </div>
                </div>
                {!swapper ? <ChartComponent /> : <SimpleTable /> }
            </div>
        </div>
    );
}

export default MixingIndicator;