import React from "react";
import { Search } from "react-feather";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { FiMoreHorizontal, FiBell, FiChevronRight, FiCornerUpLeft, FiChevronDown, FiUser, FiSliders, FiEdit, FiDollarSign, FiSun } from "react-icons/fi";
import imgUser from "../../app/images/mask47.png";
import logoEf from "../../app/images/90466.svg";
import { NavLink, useHistory, useRouteMatch } from "react-router-dom";
import useReactRouterBreadcrumbs from "../../hooks/useBreadCrumbs.tsx";
import { useSelector } from "react-redux";
import { selectUserDataState } from "../../redux/user/UserSlice";

const NavbarTop = ({ logo = false }) => {
  const breadCrumbs = useReactRouterBreadcrumbs();
  const userInfo = useSelector(selectUserDataState);
  const history = useHistory();
  let { url } = useRouteMatch();

  const goBack = () => {
    if (url) {
      if (url !== '/dashboard') {
        history.goBack()
      }
    }
    return;
  }


  const itemDrop = [
    {
      title: 'Account',
      icon: <FiUser className="iconNavbar me-2" />,
    },
    {
      title: 'Notifications',
      icon: <FiBell className="iconNavbar me-2" />,
    },
    {
      title: 'Default settings',
      icon: <FiSliders className="iconNavbar me-2" />,
    },
    {
      title: 'Preferences',
      icon: <FiEdit className="iconNavbar me-2" />,
    },
    {
      title: 'Payment',
      icon: <FiDollarSign className="iconNavbar me-2" />,
    },
  ];

  return (
    <>
      <header className="navbar bg-transparent flex-md-nowrap p-0 nav-ef-border">
        <button
          className="navbar-toggler position-absolute d-md-none collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#sidebarMenu"
          aria-controls="sidebarMenu"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="d-flex flex-row flex-wrap align-items-center justify-content-between w-75 p-2 pe-3">
          <div className="p-3 d-flex flex-row flex-wrap align-items-center">
            <img src={logoEf} alt="" className={logo ? "img-sidebar me-3" : "img-sidebar me-3 d-none"} />
            <FiCornerUpLeft onClick={() => goBack()} className="go-back-arrow me-2" />
            {breadCrumbs.map(({
              match,
              breadcrumb,
            }, index) => (
              <span key={match.url}>
                <NavLink className="bread-crumbs fw-bold text-decoration-none" to={match.url} activeStyle={{
                  color: "#BCBCBC"
                }}
                >{breadcrumb}  {(index !== breadCrumbs.length - 1) && (index !== 0) && <FiChevronRight />}</NavLink>
              </span>
            ))}
          </div>
          <div>
            <div className="form-group has-search">
              <Search className="form-control-feedback m-1 ms-2" />
              <input type="text" className="form-control inputSearch" placeholder="Search now" />
            </div>
          </div>
        </div>
        <div className="navbar-nav flex-row">
          <div className="nav-item text-nowrap mt-2 me-3">
            <FiMoreHorizontal className="iconNavbar" />
          </div>
          <div className="nav-item text-nowrap mt-2 me-3">
            <FiBell className="iconNavbar" />
          </div>
          <div className="nav-item text-nowrap box-user ms-2">
            <div>
              <img src={imgUser} alt="" className="img-user-nav" />
            </div>
            <div>
              <p className="text-name-user text-spacing fw-bold m-0">{userInfo?.user_name ?? 'Name'} {userInfo?.user_last_name ?? 'LastName'}</p>
              <p className="text-name-cargo m-0">Analyst</p>
            </div>
            <div className='dropNav'>
              <DropdownButton
                drop={'start'}
                title={<FiChevronDown className='btn-dark btn-nav' />}
              >
                {itemDrop.map((item, index) =>
                  <Dropdown.Item key={index}>
                    <div className="d-flex flex-row flex-wrap justify-content-between align-items-center">
                      <div className="d-flex flex-row align-items-center">{item.icon} {item.title}</div>
                      <div><FiChevronRight className="iconNavbar" /></div>
                    </div>
                  </Dropdown.Item>
                )}
                {/* <Dropdown.Item> */}
                  <div className="d-flex flex-row flex-wrap justify-content-between align-items-center itemNavbar border-0">
                    <div className="d-flex flex-row align-items-center"><FiSun className="iconNavbar me-2" /> Light mode</div>
                    <div className="form-check form-switch ms-2">
                      <input className="inputNavbar form-check-input mb-0 mt-0" type="checkbox" role="switch" />
                    </div>
                  </div>
                {/* </Dropdown.Item> */}
              </DropdownButton>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default NavbarTop;
