import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  value: [],
};
export const UserFoldersSlice = createSlice({
  name: "user_folders",
  initialState,
  reducers: {
    setUserFolders: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setUserFolders } = UserFoldersSlice.actions;
export const selectUserFoldersState = (state) => state.user_folders.value;

export default UserFoldersSlice.reducer;
