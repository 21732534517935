import React, { useState } from 'react';
import { FiChevronDown } from "react-icons/fi";
import { TbEqual } from "react-icons/tb";

const ItemCostEstimator = () => {
    const [dropTrip, setDropTrip] = useState(false);
    const [dropValues, setDropValues] = useState(false);
    const dataTable = [
        { total: 'Linehaul revenue', percent: '50%', cost: '$200' },
        { total: 'Estimated fuel cost', percent: '50%', cost: '$50' },
        { total: 'Additional cost', percent: '50%', cost: '$0' }
    ];

    return (
        <div className="m-3">
            <div className="col-md-12 pt-2 mb-4">
                <h1 className="heading-h1 fs-2 fw-bold mb-0">Cost estimator</h1>
                <h6 className="subtitle-graph">Calculate the cost of the lane</h6>
            </div>
            <div className='col-md-12'>
                <div onClick={() => setDropTrip(!dropTrip)} className="btnDropOff w-100">
                    <span className='letterDropOff'>Trip</span>
                    <FiChevronDown className={`icon-details ${dropTrip ? "icon-details-rotate" : ''}`} />
                </div>
                {dropTrip &&
                    <div className='w-100'>
                        <div className='d-flex flex-row justify-content-between mt-2 gap-1'>
                            <div className='col-md-6'>
                                <label className='text-name-user mb-2'>Pick up</label>
                                <select className="selectOffMenu w-100 border-end-0">
                                    <option>California</option>
                                    <option>California</option>
                                    <option>California</option>
                                    <option>California</option>
                                    <option>California</option>
                                    <option>California</option>
                                </select>
                            </div>
                            <div className='col-md-6'>
                                <label className='text-name-user mb-2'>Drop off</label>
                                <select className="selectOffMenu w-100 border-end-0">
                                    <option>California</option>
                                    <option>California</option>
                                    <option>California</option>
                                    <option>California</option>
                                    <option>California</option>
                                    <option>California</option>
                                </select>
                            </div>
                        </div>
                    </div>
                }
            </div>
            <div className='col-md-12 mt-3'>
                <div onClick={() => setDropValues(!dropValues)} className="btnDropOff w-100">
                    <span className='letterDropOff'>Values</span>
                    <FiChevronDown className={`icon-details ${dropValues ? "icon-details-rotate" : ''}`} />
                </div>
                {dropValues &&
                    <div className='w-100'>
                        <div className='col-md-12 mt-2'>
                            <span className='text-name-user'>Linehaul revenue</span>
                        </div>
                        <div className="divider-5"></div>
                        <div className='col-md-12 d-flex flex-row mt-2'>
                            <div className="col-md-4">
                                <label className='form-labelOff'>Miles</label>
                                <input type="Text" className="form-control formOff" placeholder="100 mi" />
                            </div>
                            <div className="col-md-4">
                                <label className='form-labelOff'>($)/Mile</label>
                                <input type="Text" className="form-control formOff" placeholder="$1" />
                            </div>
                            <div className="col-md-4">
                                <label className='form-labelOff'>Revenue</label>
                                <input type="Text" className="form-control formOffColor" placeholder="$100" />
                            </div>
                        </div>
                        <div className='col-md-12 mt-2'>
                            <span className='text-name-user'>Estimated fuel cost</span>
                        </div>
                        <div className="divider-5"></div>
                        <div className='col-md-12 d-flex flex-row mt-2'>
                            <div className="col-md-4">
                                <label className='form-labelOff'>Miles</label>
                                <input type="Text" className="form-control formOff" placeholder="100 mi" />
                            </div>
                            <div className="col-md-4">
                                <label className='form-labelOff'>(MPG x $/gl)</label>
                                <input type="Text" className="form-control formOff" placeholder="6" />
                            </div>
                            <div className="col-md-4">
                                <label className='form-labelOff'>Fuel cost</label>
                                <input type="Text" className="form-control formOffColor" placeholder="$96.3" />
                            </div>
                        </div>
                        <div className='col-md-12 mt-2'>
                            <span className='text-name-user'>Additional cost</span>
                        </div>
                        <div className="divider-5"></div>
                        <div className='col-md-12 d-flex flex-row mt-2'>
                            <div className="col-md-4">
                                <label className='form-labelOff mt-2'>Miles</label>
                                <input type="Text" className="form-control formOff" placeholder="$100" />
                            </div>
                            <div className='col-md-4'>
                                <label className='form-labelOff mt-1'>Dispatch fee</label>
                                <div className='d-flex flex-row'>
                                    <input type="Text" className="form-control formOff border-end-0" placeholder="6" />
                                    <select className="selectOffMenu h-auto">
                                        <option>%</option>
                                        <option>$</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <label className='form-labelOff mt-1'>Other fees</label>
                                <input type="Text" className="form-control formOff border-end-0" placeholder="$100" />
                            </div>
                        </div>
                    </div>
                }
            </div>
            <div className="divider-4"></div>
            <div className="col-md-12 w-100">
                <table className="table table-borderless tableOff">
                    <thead>
                        <tr className='text-theadOff'>
                            <th scope="col">Item</th>
                            <th scope="col" className='text-end'>Cost</th>
                            <th scope="col" className='text-end'>Percent</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dataTable.map((i, index) => <tr className='text-tbodyOff' key={index}>
                            <td>{i.total}</td>
                            <td className='text-successOff text-end'>{i.cost}</td>
                            <td className='text-end'>{i.percent}</td>
                        </tr>)}
                    </tbody>
                </table>
                <div className="divider-4"></div>
                <div className='col-md-12 d-flex flex-row flex-wrap justify-content-between align-items-center'>
                    <label className='text-name-user w-100 fw-bold'>Profit</label>
                    <div>
                        <span className='text-tbodyOff'>$0.00 - 0.00 - 0.00 </span>
                    </div>
                    <div>
                        <span className='text-tbodyOff fw-bold'>50%</span>
                    </div>
                </div>
            </div>
            <div className="divider-4"></div>
            <div className='col-md-12 d-flex flex-row justify-content-between align-items-center mt-2'>
                <div>
                    <span className='route-name'>$0.00/mi</span>
                </div>
                <div>
                    <span className='letter-success'>$100</span>
                </div>
            </div>
            <div className="col-md-12 mt-4">
                <button type="button" className="btn btn-outline-success btn-route-lg w-100">ADD VALUES</button>
            </div>
        </div>
    );
}

export default ItemCostEstimator;