import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import guardianReducer from '../redux/auth/GuardianSlice';
import registerFormReducer from '../redux/registerForm/registerFormSlice';
import blackBoxRoutesReducer from '../redux/blackBoxRoutes/blackBoxRoutesSlice';
import userDataReducer from '../redux/user/UserSlice';
import userFoldersReducer from '../redux/userFolders/UserFoldersSlice'
import selectedIndicatorReducer from '../redux/indicators/IndicatorsSlice';
import routeReducer from '../redux/route/routeSlice';

import {
  persistReducer,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from 'redux-thunk';

const reducers = combineReducers({
  guardian: guardianReducer,
  registerForm: registerFormReducer,
  blackBoxRoutes: blackBoxRoutesReducer,
  user_data: userDataReducer,
  user_folders: userFoldersReducer,
  selected_indicator_list: selectedIndicatorReducer,
  route: routeReducer
});

const persistConfig = {
  key: "root_guardian",
  storage,
  whitelist: ['guardian', 'user_data'],
};

const rootReducer = persistReducer(persistConfig, reducers);


const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk],

});

export default store;
