import React, { useRef, useEffect } from "react";


const useOutsideAlerter = (ref, openMenu, setOpenMenu) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        // alert("You clicked outside of me!");
        setOpenMenu();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line
  }, [ref]); 
}


const OutsideAlerter = (props) => {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, props.listener, props.trigger);
  return <div className={props.classNameElement ?? ''} style={{position: props.position, top: props.top}} ref={wrapperRef}>{props.children}</div>;
}

export default OutsideAlerter;
