import React from "react";
import "./App.css";
import "./styles.mediaqueries.css";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { Container } from "react-bootstrap";
import { ProtectedRoute } from "./app/ProtectedRoute";
import { useSelector } from "react-redux";
import { selectGuardianState } from "./redux/auth/GuardianSlice";
import Routes from "./Routes";

function App() {
  const authCheck = useSelector(selectGuardianState);
  let routeList = [];
  const arrRoutes = Routes?.forEach(e => {
    if (e.path === null) {
      e.Routes.forEach(subEl => {
        routeList.push(subEl);
      });
    } else {
      routeList.push(e);
    }
  });
  return (
    <Container
      fluid
      className={
        authCheck === null
          ? "container-fluid-content px-0"
          : "container-fluid-inner px-0"
      }
    >
      <BrowserRouter>
        <Switch>
          <Route
            exact
            path="/"
            render={() => {
              return authCheck === null ? (
                <Redirect to="/login" />
              ) : (
                <Redirect to="/dashboard" />
              );
            }}
          />
          {routeList.map((item, index) =>
            item.guard ? (
              <ProtectedRoute
                path={item.path}
                routes={item}
                component={item.Component}
                key={index}
                exact={item.isExact}
              />
            ) : (
              <Route
                path={item.path}
                component={item.Component}
                key={index}
                exact={item.isExact}
              />
            )
          )}

          <Route path="*">
            <Redirect to="/dashboard" />
          </Route>
        </Switch>
      </BrowserRouter>
    </Container>
  );
}

export default App;
