import React from "react";
import Layout from "../../components/layout/Layout";
import GraphCard from "../../components/GraphCard";
import Advertisement from "./Advertisement";
import StatisticsCard from "./StatisticsCard";
import { FiAlertCircle } from "react-icons/fi";
import { useSelector } from "react-redux";
import { selectUserDataState } from "../../redux/user/UserSlice";

const Dashboard = ({routes}) => {
  const userInfo = useSelector(selectUserDataState);

  return (
    <Layout routeList={routes}>
      <div className="d-flex flex-column flex-wrap flex-md-nowrap pt-4 pb-2 px-md-4 gap-3">
        <div>
          <h1 className="heading-h1 fw-bold">Hi, {userInfo?.user_name ?? 'Name'} {userInfo?.user_last_name ?? 'LastName'} <FiAlertCircle className="info-icon-dashboard ms-1" /> </h1>
          <p className="subheading-top mb-0">Here, you can manage your trips</p>
        </div>
        <Advertisement />
        <div className="d-flex flex-row flex-wrap">
          <div className="col-md-9 d-flex flex-row flex-wrap mb-2 justify-content-between">
              <GraphCard />
              <GraphCard />
              <GraphCard />
            <div className="col-lg-12 mt-2">
              <div className="card card-info-bg">
                <div className="card-body px-5 py-4">
                  <div className="content-text">
                    <h2 className="heading-h1">Truck News <FiAlertCircle className="info-icon-advertisement" /> </h2>
                    <p className="subheading-top">your weekly newsletter</p>
                  </div>
                  <div className="translucent"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 d-flex flex-row flex-wrap ps-3">
              <div className="col-md-12">
                <StatisticsCard />
              </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Dashboard;
