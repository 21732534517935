import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FiCheck } from "react-icons/fi";
import requestSignUp from "../../../services/auth/requestSignUp";
import { useHistory, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import { setForm } from "../../../redux/registerForm/registerFormSlice";

const FormCompleted = ({ currentStep, goToStep }) => {
  const history = useHistory();
  let location = useLocation();

  const { registerForm } = useSelector((state) => state.registerForm);
  const dispatch = useDispatch();


  const [showDone, setShowDone] = useState(false);

  const register = async () => {
    let response = await requestSignUp(registerForm);
    if (response.state != 200) {
      const Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
        heightAuto: true,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      return Toast.fire({
        icon: "warning",
        title: response.message,
      });
    }
    setShowDone(!showDone);
    let { from } = location.state || { from: { pathname: "/login" } };
    setTimeout(() => {
      history.replace(from);
      const formData = { };
      dispatch(setForm(formData));
    }, 9000)
  };

  return (
    <div className="content-login justify-content-center d-flex flex-row flex-wrap align-items-center">
      {!showDone ? (
        <div className="text-center content-item-completed">
          <h3 className="h3 mb-2 fw-bold text-welcomeSingUp text-center">
            Finish your registration
          </h3>
          <p className="orLogin mb-1">This is your referee ID</p>
          <h3 className="h3 mb-3 text-welcomeSingUp text-center">46754678</h3>
          <div className="item-completed" onClick={() => {!registerForm.isGoogleRegister && goToStep(0)}}>
            <FiCheck className="item-icon-completed me-2" />
            <span className="item-text-completed">
              Complete your general information
            </span>
          </div>
          <div className="item-completed" onClick={() => goToStep(1)}>
            <FiCheck className="item-icon-completed me-2" />
            <span className="item-text-completed">
              Complete your personal information
            </span>
          </div>
          <div className="item-completed" onClick={() => goToStep(2)}>
            <FiCheck className="item-icon-completed me-2" />
            <span className="item-text-completed">
              Complete your variable cost
            </span>
          </div>
          <p className="orLogin">did you finish all the steps?</p>
          <button
            type="button"
            className="w-100 btn btn-lg btn-success mb-3"
            onClick={register}
          >
            CREATE YOUR ACCOUNT
          </button>
        </div>
      ) : (
        <div className="text-center">
          <h3 className="text-ef-green fw-bolder">Account created</h3>
          <p className="text-ef-white">
            Wait a few minutes, we are{" "}
            <span className="text-success">verifying</span> your information
          </p>
          <p className="text-ef-white mb-0">
            We have sent an email to <span className="text-success">{registerForm?.user_email}</span>  to verify your
          </p>
          <p className="text-ef-white">
            email address and activate your account. The link in the email will expire in 24 hours
          </p>
        </div>
      )}
    </div>
  );
};

export default FormCompleted;
