import React from 'react';
import { FiHelpCircle } from "react-icons/fi";
import Layout from '../../components/layout/Layout';
import GeneralTabs from '../../components/GeneralTabs';
import InfoAccount from './Tabs/InfoAccount';
import Notifications from './Tabs/Notifications';
import Preferences from './Tabs/Preferences';
import { ProtectedRoute } from '../../app/ProtectedRoute';

const index = ({ routes }) => {

  const DEFAULT_ACTIVE_TAB = "account";
  const infoTabs = {
    "account": {
      id: 1,
      title: 'Account',
      child: <InfoAccount routes={routes}/>
    },
    "notifications": {
      id: 2,
      title: 'Notifications',
      child: <Notifications />
    },
    "default_settings": {
      id: 3,
      title: 'Default settings',
      child: ''
    },
    "preferences": {
      id: 4,
      title: 'Preferences',
      child: <Preferences />
    },
    "payment": {
      id: 5,
      title: 'Payment',
      child: ''
    },
  };

  // console.log(routes);

  return (
    <>
      <ProtectedRoute path={routes?.path}
        routes={routes?.Routes}
        exact={true}>
        <Layout>
          <div className="d-flex flex-column flex-wrap flex-md-nowrap pt-3 pb-2 px-md-4">
            <h1 className="heading-h1 fw-bold">Admin. your routes <FiHelpCircle className="icon-h1 ms-2" /></h1>
            <p className="subheading-top">Here, you can administrate your routes</p>
            <GeneralTabs data={infoTabs} dataRoute={routes} activeTab={DEFAULT_ACTIVE_TAB} />
          </div>
        </Layout>
      </ProtectedRoute>
      {routes.Routes.map((item, index) =>
        <ProtectedRoute
          path={item.path}
          routes={item}
          component={item.Component}
          key={index}
          exact={item.isExact} />
      )}
    </>
  )
}

export default index