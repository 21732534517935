import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { FiMapPin, FiMinus } from "react-icons/fi";
import OutsideAlerter from './OutsideAlerter';


const SearchableDropdown = ({ title = null, icon = null, itemClass, hoverOnLane = false, remove, index = null, onChange, inputClass = 'departure-field', itemId = null, spacing_top = '3rem', value = '', onUpdateValue = false }) => {
    const [openMenu, setOpenMenu] = useState(false);
    const [searching, setSearching] = useState(value);
    const [results, setResults] = useState([]);
    const [randomNumber, setRandomNumber] = useState(0);
    
    useEffect(()=>{
		setSearching(value);
	},[value])

    const openSwitch = () => {
        setOpenMenu(false);
    }

    const fetching = async (value) => {
        if (value) {
            const resp = await fetch(`https://api.geoapify.com/v1/geocode/autocomplete?text=${value}&filter=countrycode:us&type=city&lang=en&apiKey=${process.env.REACT_APP_API_KEY_MAP}`);
            const places = await resp.json();
            const currentItems = places.features.filter((item) => item.properties.result_type == 'city');
            setResults(currentItems);
        }
    }

    const selectItem = (item) => {
        onChange(item);
        setSearching(item.city ?? item.state);
        setOpenMenu(false);
    }

    const writingHandler = () => {

        const timer = setTimeout(() => {
            fetching(searching)
        }, 500);

        if (searching) {
            setOpenMenu(true);
        } else {
            setOpenMenu(false);
        }
        return () => clearTimeout(timer);
    };

    const setValue = (value) => {
		onUpdateValue(value);
	};

    return (
        <Dropdown as={'div'} className={itemClass}>
            {icon && icon}
            <div className={hoverOnLane ? 'text-start lh-1 w-100 d-flex flex-row flex-wrap justify-content-between align-items-center' : 'text-start lh-1 w-75'}>
                {title && <p className='route-name fw-bold mb-0'>{title}</p>}
                <input type="text" autoComplete="off" className={inputClass} onKeyUp={writingHandler} onChange={(event) => setSearching(event.target.value)} id={itemId} value={searching} />
                 <label htmlFor="departure">{ searching == '' && 'California' }</label>
                {hoverOnLane &&
                    <div className='d-flex flex-row flex-wrap align-items-center'>
                        <div className="btn-group" role="group" aria-label="Basic example">
                            <input type="radio" className="btn-check" onChange={(e) => setValue("PICK UP")} name={`options ${index}`} id={`option${index}`} autoComplete="off" />
                            <label className="btn btn-default btn-clean-text" htmlFor={`option${index}`}>PICK UP</label>

                            <input type="radio" className="btn-check" onChange={(e) => setValue("DROP OFF")} name={`options ${index}`} id={`option${'id' + index}`} autoComplete="off" />
                            <label className="btn btn-default btn-clean-text" htmlFor={`option${'id' + index}`}>DROP OFF</label>
                        </div>
                        <button className="btn-clean btn-clean-text btn-minus-delete" onClick={() => remove(index)}>
                            <FiMinus />
                        </button>
                    </div>
                }
            </div>
            <OutsideAlerter position={'absolute'} listener={openMenu} trigger={openSwitch}>
                <ul className={openMenu ? 'dropdown-menu show' : 'dropdown-menu'} style={{ top: spacing_top }}>
                    <Dropdown.ItemText className="text-item-drop">Departure region</Dropdown.ItemText>
                    {results.map((item, index) =>
                        <Dropdown.Item as={'div'} className="dropdown-item" key={index} onClick={() => selectItem(item.properties)}>
                            <div className="d-flex flex-row flex-wrap align-items-center">
                                <FiMapPin className='icon-drop me-2' />
                                <div>
                                    <p className="text-heading-places mb-2">{item.properties.city}</p>
                                    <p className="text-description-places mb-0">{item.properties.state}</p>
                                </div>
                            </div>
                        </Dropdown.Item>
                    )}
                </ul>
            </OutsideAlerter>
        </Dropdown>);
}

export default SearchableDropdown;