import React, { useState } from 'react';
import { FiChevronDown, FiHelpCircle, FiX } from 'react-icons/fi';
import Calendar from 'moedim';
import OutsideAlerter from '../../components/OutsideAlerter';
import { useHistory, useLocation } from 'react-router-dom';
import { useQuery } from '../../hooks/useQuery';


const IndicatorsDetailBar = (props) => {
    const [showCalendar, setShowCalendar] = useState(false);
    const [dateValue, setDateValue] = useState(new Date());
    let history = useHistory();
    let location = useLocation();
    let sectionName = useQuery();
    let { from } = location.state || { from: { pathname: "/indicators" } };
    const openCal = () => {
        setShowCalendar(false);
    }

    return (<div className="card w-100">
        <div className="card-body">
            <div className="d-flex flex-row flex-wrap align-items-center justify-content-between">
                <div className="d-flex flex-row flex-wrap align-items-center justify-content-start indicator-detail-bar-content">
                    <div className='indicator-detail-bar-item'>
                        <span className="heading-h2 fw-bold px-3 text-capitalize">{sectionName.get('section_name') ?? 'Section Name'} <FiHelpCircle className="icon-h1 ms-2" /></span>
                    </div>
                    {
                        props.showSource &&
                        <div className='indicator-detail-bar-item'>
                            <span className='indicator-info-text px-2'>Source: efRouting</span>
                        </div>
                    }

                    <div className='indicator-detail-bar-item'>
                        <span className='indicator-info-text px-2' onClick={() => setShowCalendar(!showCalendar)}> {dateValue.simpleFormatDate()} <FiChevronDown style={{ fontSize: '22px' }} /> </span>
                        {showCalendar &&
                            <OutsideAlerter position={'absolute'} listener={showCalendar} trigger={openCal}>
                                <Calendar className='calendar indicator-calendar' value={dateValue} onChange={(d) => setDateValue(d)} />
                            </OutsideAlerter>
                        }
                    </div>
                    <div className='indicator-detail-bar-item'>
                        <select name="frequency" className='form-select-sm transparent-select'>
                            <option value="1">Weekly</option>
                            <option value="2">Monthly</option>
                            <option value="3">Annual</option>
                        </select>
                    </div>
                    {
                        props.showIndicatorType &&
                        <div className='indicator-detail-bar-item'>
                            <select name="frequency" className='form-select-sm transparent-select'>
                                <option value="0">Charts</option>
                                <option value="1">Boards</option>
                            </select>
                        </div>
                    }
                </div>
                <button className="pe-3 btn-clean text-white" onClick={() => history.replace(from)}>
                    <FiX />
                </button>
            </div>
        </div>
    </div>);
}

export default IndicatorsDetailBar;