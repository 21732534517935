import React, { useEffect, useState } from 'react';
import { FiLogIn, FiBookmark, FiFolderPlus, FiDownload, FiBell, FiChevronDown } from "react-icons/fi";
import Chart from 'react-apexcharts';
import SaveSettings from './modals/SaveSettings';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Swal from "sweetalert2";
import DetailsGraphIndicators from './modals/DetailsGraphIndicators';

const GraphCard = (props) => {

    const [showSave, setShowSave] = useState(false);
    const [showSubItems, setShowSubItems] = useState(false);
    const [showDetailsGraph, setShowDetailsGraph] = useState(false);
    // const [isChecked, setIsChecked] = useState(props?.);
    const series = [{
        name: 'Cash Flow',
        data: [-40, 25, -15, 10, 12.6, 10]
    }];
    const options = {
        chart: {
            type: 'bar',
            background: '#1B252C',
            height: 120,
            toolbar: {
                show: false
            }
        },
        plotOptions: {
            bar: {
                colors: {
                    ranges: [{
                        from: -100,
                        to: 100,
                        color: '#209E59'
                    },]
                },
                columnWidth: '60%',
            }
        },
        dataLabels: {
            enabled: false,
        },
        grid: {
            show: true,
            borderColor: '#686868',
            strokeDashArray: 0,
            position: 'back',
            padding: {
                top: -18,
                bottom: 0,
            },
            xaxis: {
                lines: {
                    show: true
                }
            },
            yaxis: {
                lines: {
                    show: true
                }
            },
        },
        yaxis: {
            labels: {
                formatter: function (y) {
                    return y.toFixed(0);
                },
                // style: {
                //     colors: ['#cacaca'],
                //     fontSize: '6px',
                //     fontFamily: 'Inter',
                //     fontWeight: 400,
                // },

            },
        },
        xaxis: {
            categories: [
                'Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun'
            ],
            labels: {
                rotate: -90,
                // style: {
                //     colors: ['#cacaca'],
                //     fontSize: '6px',
                //     fontFamily: 'Inter',
                //     fontWeight: 400,
                // },
            }
        },
    };

    const alert = () => {
        Swal.fire({
            position: 'bottom',
            backdrop: false,
            background: '#121A1F',
            customClass: {
                container: 'alertClass',
            },
            html: 'your <label>sales indicator report</label> was downloaded',
            showConfirmButton: false,
            timer: 9000
        })
    }
    // console.log(props?.checkList?.lastIndexOf(Number(props.item)));
    // useEffect(() => {
    //     if (props?.checkList?.lastIndexOf(Number(props.item)) >= 0) {
    //         setIsChecked(true);
    //     }else{
    //         setIsChecked(false);
    //     }
    // }, [props.checkList, props.item]);
   
    return (
        <div className={showSubItems ? ("card card-graph-bg" + ' ' + props.classItemElement) : ("card card-graph-bg" + ' ' + props.classItem)} style={{ width: props.widthCard }}>
            <div className="card-body">
                <div className="d-flex flex-row flex-wrap mb-2">
                    <div className="col-10">
                        <h5 className="card-title title-graph fw-bold mb-1">Sales volume</h5>
                        <h6 className="card-subtitle mb-2 subtitle-graph">Weekly</h6>
                    </div>
                    {props.showChecks ?
                        <div className="col-2 text-end d-flex flex-row flex-wrap justify-content-end">
                            <div className="form-check">
                                <input className="form-check-input check-to-compare me-2" type="checkbox" value={props.item.id} checked={props.isChecked} onChange={(e) => props.handleChange(e, props.item)}/>
                            </div>
                        </div>
                        :
                        <div className="col-2 text-end">
                            <FiLogIn className="tool-icon" onClick={() => props.goTo && props.goTo(1)} />
                        </div>
                    }
                </div>
                <Chart options={options} type="bar" series={series} width="100%" />
                <div className='d-flex flex-row justify-content-between'>
                    <div className='d-flex flex-row'>
                        <button className="btn-clean text-beige">
                            <FiBookmark onClick={() => setShowSave(!showSave)} />
                        </button>
                        {!props.btnIcon &&
                            <button className="btn-clean text-beige">
                                <FiFolderPlus />
                            </button>}
                        <button className="btn-clean text-beige">
                            <FiDownload onClick={alert} />
                        </button>
                        {props.btnIcon &&
                            <div className='dropSettings'>
                                <DropdownButton
                                    drop={'end'}
                                    title={<FiBell className='btn-clean text-beige' />}
                                >
                                    <Dropdown.Item>Entire</Dropdown.Item>
                                    <Dropdown.Item>Personalized</Dropdown.Item>
                                </DropdownButton>
                            </div>}
                    </div>
                    {props.btnIcon &&
                        <div>
                            <button className="btn-clean text-beige">
                                <FiChevronDown onClick={() => setShowSubItems(!showSubItems)} />
                            </button>
                        </div>}
                </div>
                {showSubItems &&
                    <div className="d-flex flex-row flex-wrap mt-3">
                        <div className="col-8">
                            <h5 className="card-title title-graph">Sales volume</h5>
                            <h6 className="card-subtitle mb-2 subtitle-graph">Source: efrouting</h6>
                        </div>
                        <div className="col-4 text-end">
                            <h5 className="card-title title-graph">05/06/22</h5>
                        </div>
                        <div className="col-md-12 multiLineLabel d-flex flex-row align-items-end">
                            <p className="card-subtitle subtitle-graph text-spacingGraph">Lorem ipsum dolor sit amet consectetur adipisicing elit. Provident ipsa ea neque veritatis veniam. Sint, voluptate dignissimos animi dolorem quaerat aut soluta laborum distinctio velit a itaque, omnis odio laboriosam.</p>
                            <p className='subtitle-graph mb-0 text-green nav-item' onClick={() => setShowDetailsGraph(!showDetailsGraph)}>lear more</p>
                        </div>
                    </div>}
                <SaveSettings showSaveSettings={showSave} setShowSaveSettings={setShowSave} />
                <DetailsGraphIndicators showModal={showDetailsGraph} setShowModal={setShowDetailsGraph} />
            </div>
        </div>
    );
}

GraphCard.defaultProps = {
    btnIcon: null,
    widthCard: 'calc((100%/3) - 0.5rem)',
    classItemElement: '',
    classItem: '',
    goTo: null,
};

export default GraphCard;