import React from 'react';
import FullLayout from '../../../components/layout/FullLayout';
import { FiCornerUpLeft } from "react-icons/fi";
import { Controller, useForm } from 'react-hook-form';
import CustomInput from '../../../components/CustomInput';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';

const EditInfoUser = ({ routes }) => {

  let { idEdit } = useParams();

  const dynamicForm = {
    name: {
      firstName: {
        label: "First Name",
        type: "text",
        placeholder: "Enter your first name",
        defaultValue: "",
        rules: {
          required: true,
        },
      },
      lastName: {
        label: "Last Name",
        type: "text",
        placeholder: "Enter your last name",
        defaultValue: "",
        rules: {
          required: true,
        },
      },
    },
    // gender: {
    //   label: "Gender",
    //   type: "radio",
    //   options: ["male", "female"],
    //   defaultValue: "",
    //   rules: {
    //     required: true,
    //   },
    // },
    // profession: {
    //   label: "Profession",
    //   type: "select",
    //   options: ["Front-end Developer", "Back-end Developer", "Devops Engineer"],
    //   defaultValue: "",
    //   rules: {
    //     required: true,
    //   },
    // },
  };


  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({ mode: "all" });

  const formInputs = Object.keys(dynamicForm[idEdit]).map((e) => {
    const { rules, defaultValue, label } = dynamicForm[idEdit][e];

    return (
      <section key={e}>
        <label className="form-label mb-1">{label}</label>
        <Controller
          name={e}
          control={control}
          rules={rules}
          defaultValue={defaultValue}
          render={({ field }) => (
            <div>
              <CustomInput
                value={field.value}
                onChange={field.onChange}
                {...dynamicForm[idEdit][e]}
              />
            </div>
          )}
        />
        {errors[e] && <p className="text-danger text-error text-start" role="alert">This field is required</p>}
      </section>
    );
  });

  const onSubmit = (data) => console.log(data);


  return (
    <FullLayout>
      <div className="d-flex flex-row flex-wrap justify-content-center align-items-center vh-100">
        <div className='col-md-5'>
          <div className='d-flex flex-row flex-wrap'>
            <FiCornerUpLeft className="go-back-arrow me-2" />
            <h1 className="heading-h1 fw-bold text-capitalize">{idEdit}</h1>
          </div>
          <div className='d-flex flex-row flex-wrap'>
            <p className='subtitle-graph mb-0'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi repudiandae soluta magnam eius debitis dolorem quibusdam, dolor dolorum inventore accusantium aliquid porro, sit sed facilis. Quidem dolorem doloremque minima voluptatem?</p>
          </div>
          <div className='divider-3'></div>
          <form onSubmit={handleSubmit(onSubmit)}>
            {formInputs}
            <div className='d-flex flex-row flex-wrap flex-gaping mt-4'>
              <div className="col-md-6">
                <button type='button' className='btn btn-secondary w-100'>CANCEL</button>
              </div>
              <div className="col-md-6">
                <button type='submit' className='btn btn-success w-100'>SAVE CHANGES</button>
              </div>
            </div>
          </form>
        </div>

      </div>
    </FullLayout>
  )
}

export default EditInfoUser