import React, { useCallback, useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";

const MultiRangeSlider = ({ min, max, onChange, tittle, letter, letterr }) => {

    const [minVal, setMinVal] = useState(min);
    const [maxVal, setMaxVal] = useState(max);
    const minValRef = useRef(null);
    const maxValRef = useRef(null);
    const range = useRef(null);

    // Convert to percentage
    const getPercent = useCallback(
        (value) => Math.round(((value - min) / (max - min)) * 100),
        [min, max]
    );

    // Set width of the range to decrease from the left side
    useEffect(() => {
        if (maxValRef.current) {
            const minPercent = getPercent(minVal);
            const maxPercent = getPercent(+maxValRef.current.value); // Preceding with '+' converts the value from type string to type number

            if (range.current) {
                range.current.style.left = `${minPercent}%`;
                range.current.style.width = `${maxPercent - minPercent}%`;
            }
        }
    }, [minVal, getPercent]);

    // Set width of the range to decrease from the right side
    useEffect(() => {
        if (minValRef.current) {
            const minPercent = getPercent(+minValRef.current.value);
            const maxPercent = getPercent(maxVal);

            if (range.current) {
                range.current.style.width = `${maxPercent - minPercent}%`;
            }
        }
    }, [maxVal, getPercent]);

    // Get min and max values when their state changes
    useEffect(() => {
        onChange({ min: minVal, max: maxVal });
    }, [minVal, maxVal,]);


    return (
        <div className="col-md 12 d-flex flex-row flex-wrap align-items-center">
            <div className="col-md-4">
                <p className='letterModal mt-4'>{tittle}</p>
            </div>
            <div className="col-md-8">
                <div className="containerRange">
                    <input
                        type="range"
                        min={min}
                        max={max}
                        value={minVal}
                        ref={minValRef}
                        onChange={(event) => {
                            const value = Math.min(+event.target.value, maxVal - 1);
                            setMinVal(value);
                            event.target.value = value.toString();
                        }}
                        className="thumb thumb--zindex-3"
                    />
                    <input
                        type="range"
                        min={min}
                        max={max}
                        value={maxVal}
                        ref={maxValRef}
                        onChange={(event) => {
                            const value = Math.max(+event.target.value, minVal + 1);
                            setMaxVal(value);
                            event.target.value = value.toString();
                        }}
                        className="thumb thumb--zindex-4"
                    />

                    <div className="slider">
                        <div className="slider__track" />
                        <div ref={range} className="slider__range" />
                    </div>
                </div>
            </div>
            <div className="col-md 12 d-flex flex-row flex-wrap align-items-center">
                <div className="col-md-6">
                    <label className='form-labelModal'>{letterr} {minVal} {letter}</label>
                </div>
                <div className="col-md-6">
                    <label className='form-labelModal'>{letterr} {maxVal} {letter}</label>
                </div>
            </div>
        </div>

    )

    MultiRangeSlider.propTypes = {
        min: PropTypes.number.isRequired,
        max: PropTypes.number.isRequired,
        onChange: PropTypes.func.isRequired
    };
}

export default MultiRangeSlider