import React, {useState} from 'react';
import Swal from "sweetalert2";

const Alerts = (title ='Changes saved', text = 'your settings were moved to settings 1' ) => {
  const [show, setShow] = useState(false);
  const alert = () => {
    setShow(true);
    let timerInterval;
    Swal.fire({
      title: title,
      text: text,
      background: '#121A1F',
      showCancelButton: false,
      showConfirmButton: false,
      timer: 9000,
      willClose: () => {
        clearInterval(timerInterval)
      }
    })
  };
  return (
    <button onClick={alert}></button>
  )
}

export default Alerts